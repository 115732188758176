import {Component, OnDestroy, OnInit} from '@angular/core';
import {Notifications} from "../../../Entities/Notifications";

@Component({
  selector: 'app-notifications-center',
  templateUrl: './notifications-center.component.html',
  styleUrls: ['./notifications-center.component.scss'],
})
export class NotificationsCenterComponent implements OnInit, OnDestroy {


  constructor() { }

  async ngOnInit() {

  }

  ngOnDestroy(): void {
  }



}
