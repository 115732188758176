import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import * as Parse from "parse";
import {UserService} from "./user.service";
import {CurrentUserService} from "./current-user.service";

@Injectable({
  providedIn: 'root'
})
export class MainChildGuard  {

  constructor(private router: Router, private cus: CurrentUserService) {
  }
  // Function for resting purpose only
  // getCurrentUser(): Promise<boolean> {
  //   return new Promise((res, rej) => {
  //     setTimeout(() => {
  //       res(true)
  //     }, 1500);
  //   })
  // }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise(async (res, rej) => {
      const currentCachedUser = await Parse.User.currentAsync();
      if (!currentCachedUser) {
        this.router.navigateByUrl('login');
        res(false);
        return;
      }
      const currentUser = await Parse.User.become(currentCachedUser.getSessionToken());
      if (!currentUser) {
        this.router.navigateByUrl('login');
        res(false);
        return;
      }
      if (!currentUser.get('emailVerified')) {
        this.router.navigateByUrl('verify');
        res(false);
        return;
      }
      if (!currentUser.get('profileCompleted')) {
        this.router.navigateByUrl('make-profile');
        res(false);
        return;
      }
      res(true);
    });

  }

}
