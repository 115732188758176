import { Component, OnInit } from '@angular/core';
import {ModalService} from "../../providers/modal.service";
import {CurrentUserService} from "../../providers/current-user.service";
import {Profile} from "../../Entities/Profile";
import {NotificationsService} from "../../providers/notifications.service";
import {TranslatorService} from "../../providers/translator.service";
import {ToasterService} from "../../providers/toaster.service";
import * as Parse from "parse";
import {JobsService} from "../../providers/jobs-service.service";
import {TourService} from "ngx-ui-tour-ionic";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  notifs: Parse.Object[];

  user: Parse.User;
  profile: Parse.Object;




  constructor(private translator: TranslatorService, private tourService: TourService, public jobsService: JobsService, private toaster: ToasterService, public modal: ModalService, private currentUser: CurrentUserService, public notifications: NotificationsService, public trans: TranslateService) {
    this.currentUser.currentUser.subscribe(async user => {
      this.user = user;
      this.profile = await new Profile().getUserProfile(user);
    })
  }

  async ngOnInit() {

    // const query = await new Notifications().query;
    // query.ascending('dismissed');
    // this.notifs = await ParseUtilities.liveArray(query);
    // this.notifications.getStaticNotifications();
  }


  async dismissNotification(notification: Parse.Object) {
    try {
      await notification.save('dismissed', true);
    } catch (e) {
      await this.toaster.showUnexpectedErrorToast(e.message);
    }
  }

  askNotification() {
    new Notification('Hello', {body: 'World'})
    Notification.requestPermission().then((result) => {
      if (result === "granted") {
      }
    });
  }
}
