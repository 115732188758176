import { Component, OnInit } from '@angular/core';
import {TranslatorService} from "../../../providers/translator.service";
import {CurrentUserService} from "../../../providers/current-user.service";

@Component({
  selector: 'app-langswitcher',
  templateUrl: './langswitcher.component.html',
  styleUrls: ['./langswitcher.component.scss'],
})
export class LangSwitcherComponent implements OnInit {


  constructor(public trans: TranslatorService, private cus: CurrentUserService) { }

  ngOnInit() {
    const userLang = this.cus.currentUser.value?.get('lang');
    if (userLang) {
      this.trans.changeLang(userLang);
    }
  }

  changeLang($event: Event) {
    console.log($event);
  }
}
