import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {IonNav} from "@ionic/angular";
import {CurrentUserService} from "../../../../providers/current-user.service";
import {ToasterService} from "../../../../providers/toaster.service";
import {LoadingService} from "../../../../providers/loading.service";
import * as Parse from "parse";
import {ProfileEmailVerifyComponent} from "../profile-email-verify/profile-email-verify.component";

@Component({
  selector: 'app-profile-email-change',
  templateUrl: './profile-email-change.component.html',
  styleUrls: ['./profile-email-change.component.scss'],
})
export class ProfileEmailChangeComponent implements OnInit {

  @HostListener('unloaded')

  emailForm: UntypedFormGroup;
  formValueChanged: boolean;
  currentUser: Parse.User;

  constructor(private fb: UntypedFormBuilder, private nav: IonNav, private user: CurrentUserService, private toast: ToasterService, private loading: LoadingService) {
    this.user.getCurrentUser();
    this.emailForm = this.fb.group({
      email: ['', Validators.required],
    })
  }

  ngOnInit() {
    this.currentUser = this.user.currentUser.value;
    // if (this.currentUser.get('newEmail')) {
    //   this.nav.push(ProfileEmailVerifyComponent);
    // }
    this.emailForm.patchValue({email: this.currentUser?.get('newEmail')});
    this.emailForm.valueChanges.subscribe(x => {
      this.formValueChanged = true;
    })

  }


  async changeEmail() {
    if (this.emailForm.valid) {
      try {
        await this.loading.presentLoading();
        await Parse.Cloud.run('changeEmail', this.emailForm.value);
        this.nav.push(ProfileEmailVerifyComponent);
        await this.loading.removeLoading();
      } catch(e) {
        await this.toast.showErrorToast(e.message);
        await this.loading.removeLoading();
      }
    }
  }

  async goBack() {
    this.user.getCurrentUser();
    await this.nav.popToRoot();
  }

}
