import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import * as Parse from "parse";
import {User} from "../Entities/User";
import {CurrentUserService} from "./current-user.service";

@Injectable({
  providedIn: 'root'
})
export class LoginGuard  {

  constructor(private router: Router, private cus: CurrentUserService) {
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise(async (res, rej) => {
      const currentCachedUser = await Parse.User.currentAsync();
      if (!currentCachedUser) {
        res(true);
        return;
      }
      this.router.navigateByUrl('dashboard');
      res(false);


    });
    // return new Promise((res, rej) => {
    //   setTimeout(() => {
    //     const currentUser = this.cus.currentUser.value;
    //     if (currentUser) {
    //       this.router.navigateByUrl('/dashboard');
    //       res(false);
    //     } else {
    //       res(true);
    //     }
    //   }, 300);
    // })
  }

}
