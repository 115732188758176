import {Component, OnInit} from '@angular/core';
import {AlertController, IonNav, ModalController} from "@ionic/angular";
import {CurrentUserService} from "../../../../providers/current-user.service";
import * as Parse from "parse";
import {ToasterService} from "../../../../providers/toaster.service";
import {LoadingService} from "../../../../providers/loading.service";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'app-profile-email-verify',
  templateUrl: './profile-email-verify.component.html',
  styleUrls: ['./profile-email-verify.component.scss'],
})
export class ProfileEmailVerifyComponent implements OnInit {

  currentUser: Parse.User;
  verifyCodeInput: any;

  constructor(private modalController: ModalController,private trans: TranslateService, private router: Router, private nav: IonNav, private cus: CurrentUserService, private loading: LoadingService, private toaster: ToasterService, private alert: AlertController) {
  }

  async ngOnInit() {
    await this.cus.getCurrentUser();
    this.currentUser = this.cus.currentUser.value;
  }

  goBack() {
    this.nav.pop();
  }

  async verifyNewEmail() {
    try {
      await Parse.Cloud.run('verifyNewEmail', {code: this.verifyCodeInput});
      const alert = await this.alert.create({
        backdropDismiss: false,
        header: this.trans.instant('emailChangedSuccessHeader'),
        message: this.trans.instant('emailCHangedSuccessMessage'),
        buttons: [
          {
            text: 'Ok', handler: async () => {
              this.modalController.dismiss({logOutUser: true}).then(async () => {
                await Parse.User.logOut();
                localStorage.clear();
                this.cus.currentUser.next(undefined);
                this.router.navigateByUrl('/login');
                window.location.reload();
              })


            }
          }
        ]
      })
      alert.present()
    } catch (e) {
      console.log(e.code);
      this.toaster.showErrorToast(e.code);
    }
  }
}
