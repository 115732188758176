import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import * as Parse from 'parse';
import {Location} from "@angular/common";
import {CurrentUserService} from "../../providers/current-user.service";
import {LiveArray} from "../../Utilities/livearray";
import {BidService} from "../../providers/bid.service";
import {ToasterService} from "../../providers/toaster.service";
import {ModalController, NavParams} from "@ionic/angular";

interface myObject {
  name: string;
  age: number;
}

@Component({
  selector: 'app-request-details',
  templateUrl: './request-details.component.html',
  styleUrls: ['./request-details.component.scss'],
})
export class RequestDetailsComponent implements OnInit {

  // footerState: IonPullUpFooterState;

  bidsLiveArray: LiveArray;
  currentUserBid: Parse.Object;
  request: Parse.Object = new Parse.Object('Jobs');
  bidderProfiles: Parse.Object[] = [];
  bidsWithProfile: {bid: Parse.Object, profile: Parse.Object}[] = [];

  jobIsDismissed: boolean = true;

  constructor(private modalCtrl: ModalController, private navParams: NavParams, private route: ActivatedRoute, private location: Location, public cus: CurrentUserService, private bidService: BidService, private toaster: ToasterService) {
    this.route.params.subscribe(async p => {
      try {
        this.request = this.navParams.get('request');
        // this.request = await new Parse.Query('Jobs').get(p.id);
        const dismissedJob = await new Parse.Query('DismissedJobs').equalTo('job', this.request).equalTo('dismissedBy', this.cus.currentUser.value).first();
        this.jobIsDismissed = !!dismissedJob;
        const bidsQuery = new Parse.Query('Bids')
          .equalTo('job', this.request)
          .include('owner')
          .ascending('amount');
        this.bidsLiveArray =  new LiveArray(bidsQuery) ;
        await this.bidsLiveArray.getResults();
        if (this.bidsLiveArray.results && this.bidsLiveArray.results.length) {
          console.log(this.bidsLiveArray.results);
          this.currentUserBid = this.bidsLiveArray.results.find((b) => b?.get('owner').id === this.cus.currentUser.value?.id)
        }
        this.bidsLiveArray.onCreate.subscribe(o => {
          this.updateBidsWithProfiles();
        })
        this.bidsLiveArray.onDelete.subscribe(o => {
          this.updateBidsWithProfiles();
        })
        this.updateBidsWithProfiles();
      } catch (e) {
        console.log(e);
        if (e.code === 101) {
          this.location.back();
        }
      }
    })
  }

  ngOnInit() {
    // this.footerState = IonPullUpFooterState.Collapsed;
    console.log(this.request.get('trailersNeeded'));
  }

  async updateBidsWithProfiles() {
    this.bidderProfiles = await new Parse.Query('Profile').containedIn('owner', this.bidsLiveArray.results.map((o) => o.get('owner'))).find();
    this.bidsWithProfile = this.bidsLiveArray.results.map(o => {
      return {bid: o, profile: this.bidderProfiles.find(p => p.get('owner').id === o.get('owner').id)}
    })
  }

  isWinnerOrAdmin(): boolean {
    return !!this.request.get('winner') || this.cus.isRole('Admin') || this.cus.isRole('Client');
  }

  isAdminOrClient(): boolean {
    return !!this.cus.isRole('Admin') || this.cus.isRole('Client');
  }

  logRow(row: any) {
    console.log(row);
  }

  userIsRequestWinner(): boolean {
  return this.cus.currentUser.value?.id === this.request.get('winner')?.id;
  }

  async asignCarrier(row: any) {
    try {
      this.request.set('winner', row.profile.get('owner'))
      await this.request.save({}, {context: {notifyWinner: true, setWinner: true}});
    } catch(e) {
      console.log(e);
    }
  }

  async unasignCarrier() {
    try {
      this.request.unset('winner')
      await this.request.save({}, {context: {notifyWinner: false, unasignCarrier: true}});
    } catch(e) {
      console.log(e);
    }
  }

  bidIsWinner(row: any) {
    const bidderId = row.bid.get('owner').id;
    const requestWinnerId = this.request.get('winner')?.id;
    return bidderId === requestWinnerId;
  }

  async bid() {
    await this.bidService.bid(this.request);
    this.currentUserBid = this.bidsLiveArray.results.find((b) => b.get('owner').id === this.cus.currentUser.value.id)
  }

  async dismiss() {
    try {
      const dismissedJobs = new Parse.Object('DismissedJobs')
      dismissedJobs.set('job', this.request);
      await dismissedJobs.save();
      this.jobIsDismissed = true;
      this.toaster.showSaveSuccessToast()
    } catch (e) {
      this.toaster.showUnexpectedErrorToast(e.message);
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  // optional capture events
  footerExpanded() {
    console.log('Footer expanded!');
  }

  // optional capture events
  footerCollapsed() {
    console.log('Footer collapsed!');
  }

  // toggle footer states
  // toggleFooter() {
  //   this.footerState = this.footerState === IonPullUpFooterState.Collapsed ? IonPullUpFooterState.Expanded : IonPullUpFooterState.Collapsed;
  // }

  checkCertif(value: any, row: any) {
    console.log('Value : ', value);
    console.log('Row : ', row);
  }

  goToImages() {
    const imagesDiv = document.getElementById('job-images');
    imagesDiv.scrollIntoView({behavior: "smooth", block: 'center'});
  }
}
