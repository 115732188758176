import {Injectable} from '@angular/core';
import * as Parse from 'parse';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ParseService {

  // serverUrl = 'http://localhost:1337';

  // serverUrl = environment.production ? 'https://waytoshipbackend-18869.nodechef.com' : 'https://waytoshipbackend-18869.nodechef.com';
  serverUrl = 'https://serverwts.webeasy.ca';
  // serverUrl = 'http://localhost:1337';
  // wsUrl = environment.production ? 'wss://waytoshipbackend-18869.nodechef.com' : 'wss://waytoshipbackend-18869.nodechef.com';
  wsUrl = 'wss://wsswts.webeasy.ca';
  // wsUrl = 'ws://localhost:8080';
  // appId = environment.production ? '15813061176' : '15813061176'
  // appId = 'HuofxWCyV8yYJ3XboRZe9Q2HO3qW2IWl_prod'
  appId = 'HuofxWCyV8yYJ3XboRZe9Q2HO3qW2IWl_prod'
  // jsKey = environment.production ? '8pP7tvdIqbzBCcXn2luCS8jGgW4RbYWOWr8wgtU1qR6wjFfWB26RZNuv8V0S7ze' : '8pP7tvdIqbzBCcXn2luCS8jGgW4RbYWOWr8wgtU1qR6wjFfWB26RZNuv8V0S7ze'
  jsKey = 'pGdqetoVm4cbnVY24NzcgpGCR'

  constructor() {
    // @ts-ignore
    Parse.liveQueryServerURL = this.wsUrl;
    // @ts-ignore
    Parse.serverURL = `${this.serverUrl}/parse`;
    // Parse.initialize('ulThuE8FvE3Vx3EaxqWca3rzYUQ9dEWN_dev', 'sY3yRea6W4o4bMCzrdAh1qfD2KVvPY7K');

    Parse.initialize(this.appId, this.jsKey);
    // Parse.initialize('5813064251', 'MasterKeyBruh');
  }

  // serverUrl = 'http://localhost:3000';
  // wsUrl =  'ws:/127.0.0.1:8080';
  //
  // constructor() {
  //   // @ts-ignore
  //   Parse.liveQueryServerURL = this.wsUrl;
  //   // @ts-ignore
  //   Parse.serverURL = `${this.serverUrl}/parse`;
  //   Parse.initialize('15813061176', 'ckajKey');
  // }

}
