import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {GuardService} from "./providers/guard.service";
import {TranslateModule} from "@ngx-translate/core";

const routes: Routes = [
  {
    path: 'main',
    loadChildren: () => import('./main/main.module').then( m => m.MainModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule), data: {title: 'Authentication'},
  },
  {
    path: '**',
    redirectTo: '#/login'
  },
];





@NgModule({
  imports: [
    TranslateModule,
    RouterModule.forRoot(routes, {useHash: true, preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule, TranslateModule],
  providers: [GuardService]
})
export class AppRoutingModule {
}
