import { Injectable } from '@angular/core';
import {SwUpdate} from "@angular/service-worker";
import {AlertController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import {ToasterService} from "./toaster.service";
import * as Parse from 'parse';
@Injectable({
  providedIn: 'root'
})
export class SwUpdaterService {

  constructor(private swUpdate: SwUpdate, private alertController: AlertController, private trans: TranslateService, private toaster: ToasterService) {
    const updateQuery = new Parse.Query('Update')
    updateQuery.first().then(async (o) => {
      const update = o;
      console.log(update);
    })
    updateQuery.subscribe().then(sub => {
      sub.on('update', (o) => {
        console.log('Check for update');
        this.checkForUpdate();
      });
    });
  }

   async checkForUpdate() {
     console.log('SW isEnabled ? : ', this.swUpdate.isEnabled)
     if (this.swUpdate.isEnabled) {
      try {
        const gotUpdate = await this.swUpdate.checkForUpdate();
        console.log('Got Update ? : ', gotUpdate)
        if (gotUpdate) {
          await this.presentAlertConfirm();
        }
      } catch (e) {
        await this.toaster.showUnexpectedErrorToast(e.message);
        console.log(e);
      }
    }
  }


  async presentAlertConfirm() {
    console.log('Inside PresentAlertConfirm');
    const alert = await this.alertController.create({
      backdropDismiss: false,
      header: this.trans.instant('newUpdate'),
      message: this.trans.instant('newSwVersionAvailable'),
      buttons: [
       {
          text: this.trans.instant('update'),
          id: 'confirm-button',
          handler: () => {
            this.swUpdate.activateUpdate()
              .then(u => window.location.reload())
              .catch(e => {
                this.toaster.showUnexpectedErrorToast(e.message);
                console.log(e);
              })
          }
        }
      ]
    });

    await alert.present();
  }
}
