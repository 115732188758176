import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import * as Parse from 'parse';
import {ToasterService} from "../../../providers/toaster.service";

@Component({
  selector: 'app-notifications-settings-modal',
  templateUrl: './notifications-settings-modal.component.html',
  styleUrls: ['./notifications-settings-modal.component.scss'],
})
export class NotificationsSettingsModalComponent implements OnInit {

  public owner: Parse.User;

  constructor(private modalController: ModalController, public trans: TranslateService, private toaster: ToasterService) {
    this.owner = Parse.User.current();
  }

  async ngOnInit() {

  }

  closeModal() {
    this.modalController.dismiss();
  }


}
