import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CurrentUserService} from "../../providers/current-user.service";
import * as Parse from 'parse'
import {LiveArray} from "../../Utilities/livearray";
import {ToasterService} from "../../providers/toaster.service";
import {skip} from "rxjs/operators";
import {AlertController, ModalController, NavParams} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import {BidService} from "../../providers/bid.service";
import {Observable} from "rxjs";
import {NavigateToMapService} from "../../providers/navigate-to-map.service";
import * as _ from 'lodash';
import {RequestDetailsComponent} from "../request-details/request-details.component";
import {ModalService} from "../../providers/modal.service";
import {JobsService} from "../../providers/jobs-service.service";
import {TourService} from "ngx-ui-tour-ionic";

class Page {
  // The number of elements in the page
  size: number = 0;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 0;
}

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss'],
})
export class RequestsComponent implements OnInit, AfterViewInit {

  rows = [];
  pageOffset: number;
  pageSize: number = 25;
  // totalElements: number;
  searchText: string;

  dateNow = new Date();

  segment: string;

  allBids: Parse.Object[] = [];

  toBeApprovedCount: number;
  yourBidsCount: number;

  notesDebounceTimeout: any;

  jobsTypes: Parse.Object[];
  jobsFiltersSettings: Parse.Object[];
  statusSelectOptions: string[] = ['all', 'waitingApproval', 'waitingForBids', 'waitingForCompletion', 'waitingForClientAnswer', 'waitingForCarrierAnswer', 'cancelRequestAsked'];

  constructor(
    private changeRef: ChangeDetectorRef,
    public jobsService: JobsService,
    private navToService: NavigateToMapService,
    private bidService: BidService,
    public trans: TranslateService,
    private alertCtrl: AlertController,
    private route: ActivatedRoute,
    private router: Router,
    public cus: CurrentUserService,
    private toaster: ToasterService,
    private modalCtrl: ModalController,
    public modalService: ModalService,
    private tourService: TourService
  ) {
  }

  async ngOnInit() {
    await this.cus.getCurrentUser();
    if (this.cus.isRole('Client') && this.cus.currentUser.value.get('showClientTour') && !this.cus.currentUser.value.get('passwordChangeNeeded')) {
      this.tourService.initialize([
        {popoverClass: 'shiftUp', anchorId: 'segmentAll', content: this.trans.instant('tour.client.requests.segmentAllContent'), title:  this.trans.instant('tour.client.requests.segmentAllTitle')},
        {popoverClass: 'shiftUp', anchorId: 'segmentToBeApproved', content: this.trans.instant('tour.client.requests.segmentToBeApprovedContent'), title:  this.trans.instant('tour.client.requests.segmentToBeApprovedTitle')},
        {popoverClass: 'shiftUp', anchorId: 'segmentAssigned', content: this.trans.instant('tour.client.requests.segmentAssignedContent'), title:  this.trans.instant('tour.client.requests.segmentAssignedTitle')},
        {popoverClass: 'shiftUp', anchorId: 'segmentCompleted', content: this.trans.instant('tour.client.requests.segmentCompletedContent'), title:  this.trans.instant('tour.client.requests.segmentCompletedTitle')},
        {popoverClass: 'shiftUp', anchorId: 'segmentDeleted', content: this.trans.instant('tour.client.requests.segmentDeletedContent'), title:  this.trans.instant('tour.client.requests.segmentDeletedTitle')},
      ], {nextBtnTitle: this.trans.instant('next'), prevBtnTitle: this.trans.instant('back'),isOptional: true ,enableBackdrop: true, backdropConfig: {backgroundColor: '#000000c4'}, placement: {alignment: 'end'}})
      this.tourService.start();
      this.tourService.end$.subscribe(async e => {
        await this.cus.currentUser.value.save({showClientTour: false});
      })
    }


    // const list: Parse.Object[] = [];

    // Timer used to keep track of when the job was published in real time
    setInterval(() => {
      this.dateNow = new Date();
    }, 1000);

    // When the component open, if we have an ID in the URL param that means we most likely clicked on a link
    // to the job details we open the job details with the ID
    const singleJobId = this.route.snapshot.paramMap.get('id');
    if (singleJobId) {
      this.goToDetails(singleJobId);
    }

    // Get all job types from the database, query all job type filters for current user and in createSetting
    // we create the filter for job types if they do not exist
    this.jobsTypes = await new Parse.Query('JobsTypes').findAll();
    this.jobsFiltersSettings = await new Parse.Query('JobsFiltersSettings')
      .equalTo('owner', Parse.User.current())
      .containedIn('jobType', this.jobsTypes)
      .find();
    this.createSettings();

    this.route.queryParams
      .subscribe(async (params) => {
          this.segment = params.segment ? params.segment : 'all';
          this.pageOffset = params.page ? params.page : 0;
          this.searchText = params.s ? params.s : undefined;
          await this.jobsService.getAllJobs({searchValue: this.searchText, segment: this.segment, pageOffset: this.pageOffset, pageSize: this.pageSize})
        }
      );

    this.jobsService.changeDetectionEmitter.subscribe(() => {this.setPage()})

  }



  async ngAfterViewInit() {
  }

  newRequest() {
    this.router.navigate(['newRequest']);
  }


  allRequestsFiltered(): any[] {
    console.log('filtering requests')
    if (this.jobsService.currentViewJobs?.length) {
      return this.jobsService.currentViewJobs.map(o => {
              return {id: o.id, ...o.attributes, bid: this.allBids.find((b) => {return b.get('owner')?.id === this.cus.currentUser.value.id && b.get('job').id === o.id}), bidCount: this.allBids.filter(b => b.get('job').id === o.id).length}
            });
    }
    // if (this.allRequests?.length) {
    //   if (this.segment === 'offers') {
    //     const bidsToLog = this.allBids.map(o => {
    //       if (o.get('owner').id === this.cus.currentUser.value.id && o.get('job')?.createdAt) {
    //         return {id: o.get('job').id, ...o.get('job').attributes, bid: o,bidCount: this.allBids.filter(b => b.get('job').id === o.id).length}
    //       }
    //     });
    //     return bidsToLog.filter(o => o && o.id && o.winner?.id !== this.cus.currentUser.value.id);
    //   } else {
    //     return this.allRequests.map(o => {
    //       return {id: o.id, ...o.attributes, bid: this.allBids.find((b) => {return b.get('owner')?.id === this.cus.currentUser.value.id && b.get('job').id === o.id}), bidCount: this.allBids.filter(b => b.get('job').id === o.id).length}
    //     });
    //   }
    //
    // }
  }



  // async getRequests() {
  //   try {
  //     let requestsQuery = new Parse.Query('Jobs');
  //     const dismissedJobsQuery = new Parse.Query('DismissedJobs');
  //     dismissedJobsQuery.equalTo('dismissedBy', this.cus.currentUser.value);
  //     const dismissedJobs = await dismissedJobsQuery.find();
  //     // Nouvelle array pour acceuilir les constraint de la query des Requests. Nous faison un array pour looper dedans à la fin vu que nous avons
  //     // plusieurs constraint à ajouter.
  //     const constraintQueries = [];
  //     // Search field est déclarer au niveau de la classe pour dire quel champs inclure dans la recerche et ajouter a la query
  //     if (this.searchText) {
  //       this.searchFields.forEach(field => {
  //         const q = new Parse.Query('Jobs')
  //         q.matches(field, new RegExp(this.searchText), 'i');
  //         constraintQueries.push(q);
  //       })
  //       requestsQuery = Parse.Query.or(...constraintQueries);
  //     }
  //     requestsQuery.equalTo('approved', true)
  //     if (this.segment === 'dismissed') {
  //       requestsQuery.containedIn('objectId', dismissedJobs.map(j => j.get('job').id));
  //     }
  //     if (this.segment === 'all') {
  //       requestsQuery.notContainedIn('objectId', dismissedJobs.map(j => j.get('job').id));
  //     }
  //     if (this.segment === 'toBeApproved') {
  //       requestsQuery.equalTo('approved', false);
  //     }
  //     if (this.segment === 'contracts') {
  //       requestsQuery.equalTo('winner', this.cus.currentUser.value);
  //     }
  //     requestsQuery.containedIn('freightType', _.map(this.jobsFiltersSettings.filter(f => f.get('checked')), 'attributes.jobType.attributes.name'))
  //     requestsQuery.equalTo('completed', false);
  //     requestsQuery.doesNotExist('deletedAt');
  //     this.totalElements = await requestsQuery.count();
  //     requestsQuery.limit(this.pageSize);
  //     requestsQuery.skip(this.pageOffset * this.pageSize);
  //     // requestsQuery.addAscending(['priority']);
  //     requestsQuery.addDescending(['createdAt']);
  //     requestsQuery.include('approvedBy');
  //     this.allRequests = await requestsQuery.find();
  //     const bidsQuery = new Parse.Query('Bids');
  //     // bidsQuery.containedIn('job', this.allRequests);
  //     // bidsQuery.equalTo('owner', this.cus.currentUser.value)
  //     this.allBids = await bidsQuery.find();
  //     // console.log(this.allRequests);
  //     // this.yourBidsCount = this.allBids.filter(b => b.get('owner')?.id === this.cus.currentUser.value.id && b.get('job')?.createdAt && b.get('job')?.attributes.winner.id !== this.cus.currentUser.value.id).length;
  //     if (this.cus.isRole('Admin')) {
  //       const toBeApprovedQ = new Parse.Query('Jobs');
  //       toBeApprovedQ.equalTo('approved', false)
  //       toBeApprovedQ.doesNotExist('deletedAt');
  //       this.toBeApprovedCount = await toBeApprovedQ.count();
  //
  //     }
  //   } catch (e) {
  //     await this.toaster.showUnexpectedErrorToast(e.message);
  //   }
  // }

  async setPage($event?: any) {
    await this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: {page: $event?.offset ? $event.offset : 0, s: this.searchText, segment: this.segment, cb: Math.floor(Math.random() * Date.now()).toString(36)}
    });
    // if (this.jobsService.allJobsCount <= this.totalElements) {
    //   await this.router.navigate(['.'], {
    //     relativeTo: this.route,
    //     queryParams: {page: 0, s: this.searchText, segment: this.segment}
    //   });
    // }

  }

  async search($event: any) {
    await this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: {page: this.pageOffset, s: this.searchText, segment: this.segment}
    });
  }

  async segmentChanged(event: any) {
    await this.router.navigate(['.'], {relativeTo: this.route, queryParams: {page: 0, s: '', segment: event}});
  }

  logRow(row: any) {
    console.log(row);
  }

  async dismiss(row: any) {
    const jobToDismiss = this.jobsService.currentViewJobs.find(o => o.id === row.id);
    try {
      const dismissedJobs = new Parse.Object('DismissedJobs')
      dismissedJobs.set('job', jobToDismiss);
      await dismissedJobs.save();
      this.setPage(this.pageOffset);
    } catch (e) {
      this.toaster.showUnexpectedErrorToast(e.message);
    }
  }

  modifyJob(id) {
    this.router.navigate(['newRequest', id]);

  }

  async deleteJob(id) {
    const alert = await this.alertCtrl.create({
      header: this.trans.instant('areYouSure'),
      subHeader: this.trans.instant('permanentDeleteText'),
      cssClass: 'delete-alert',
      buttons: [
        {
          text: this.trans.instant('cancel'),
          role: 'cancel',
        },
        {
          text: this.trans.instant('confirm'),
          role: 'confirm',
          cssClass: 'color-danger',
          handler: async () => {
            try {
              await this.jobsService.currentViewJobs.find(o => o.id === id).save({}, {context: {delete: true}});
            } catch (e) {
              await this.toaster.showUnexpectedErrorToast(e.message);
            }
          }
        }
      ]
    })
    await alert.present();
  }

  async goToDetails(id) {
    try {
      const modal = await this.modalCtrl.create({
        component: RequestDetailsComponent,
        componentProps: {request: await new Parse.Query('Jobs').get(id)},
        cssClass: 'jobDetailsModal'
      });
      modal.present();
    } catch (e) {
      console.log(e);
      await this.toaster.showUnexpectedErrorToast(e.message);
    }

    // this.router.navigate(['request', id]);
  }

  async bid(j: any) {
    const job = this.jobsService.currentViewJobs.find(o => o.id === j.id);
    await this.bidService.bid(job);
    // await this.getRequests();

  }

  async completeJob(id) {
      const alert = await this.alertCtrl.create({
        header: this.trans.instant('areYouSure'),
        subHeader: this.trans.instant('completeJobText'),
        cssClass: 'delete-alert',
        buttons: [
          {
            text: this.trans.instant('cancel'),
            role: 'cancel',
          },
          {
            text: this.trans.instant('confirm'),
            role: 'confirm',
            cssClass: 'color-danger',
            handler: async ({soldPrice}) => {
              try {
                await this.jobsService.currentViewJobs.find(r => r.id === id).save({completed: true, soldPrice: Number(soldPrice)}, {context: {completeJob: true}});
                await this.toaster.showSaveSuccessToast();
              } catch (e) {
                await this.toaster.showUnexpectedErrorToast(e.code);
              }
            }
          },
        ],
        inputs: [
          {
            name: 'soldPrice',
            type: 'number',
            placeholder: 'Inscrivez le prix de vente (Optionnel)',
          },
        ]
      })
      await alert.present();
  }

  async sendServiceMapNav(row: any ){
    this.navToService.fetchCoordsObj(row)
  }

  async setNote($event: any, row) {
    clearTimeout(this.notesDebounceTimeout);
    this.notesDebounceTimeout = setTimeout(async() => {
      if ($event.keyCode != 13) {
        const value = $event.target.value;
        const jobId = row.id;
        try {
          const job = this.jobsService.currentViewJobs.find((o) => o.id === jobId);
          await job.save({notes: value});
          await this.toaster.showSaveSuccessToast();

        } catch (e) {
          console.log(e);
          await this.toaster.showErrorToast(e.code);
        }
      }
    }, 1000);

  }

  getRowClass(row) {
    // if (!this.cus.isRole('Admin')) {
    //   return;
    // }
    const approvedById = row.approvedBy?.id;
    if (approvedById) {
      return {
        'approved-by-dan': approvedById === 'F7tSjLKpeE',
        'approved-by-steph': approvedById === 'qfrnAdzc5t',
      }
    }
  }

  // Create settings object if they don't exist. For example, will be created when someone opens the notifications parameters for the first time.
  async createSettings() {
    for (const j of this.jobsTypes) {
      const notificationSetting = this.jobsFiltersSettings.find(s => s.attributes.jobType.id === j.id);
      if (notificationSetting) return;
      try {
        const newSetting = await new Parse.Object('JobsFiltersSettings')
          .save({owner: Parse.User.current(), jobType: j, checked: true});
        this.jobsFiltersSettings.push(newSetting);
      } catch (e) {
        throw new Error(e);
      }
    }
  }

  async onFilterCheckboxChange($event: CustomEvent, jobFilter: Parse.Object) {
    const checked = $event.detail.checked;
    try {
    await jobFilter.save({checked: checked});
      // this.jobsService.getAllJobs();
    this.setPage();
    } catch(e) {
      this.toaster.showSaveErrorToast();
    }
  }

  openLogs(id) {
    this.modalService.openJobLogModal({job: this.jobsService.currentViewJobs.find(r => r.id === id)})
  }

  onStatusFilterChange() {
    this.setPage();
  }

  async changeJobStatus($event, row: any) {
        const value = $event.target.value;
        const jobId = row.id;
        try {
          const job = this.jobsService.currentViewJobs.find((o) => o.id === jobId);
          await job.save({status: value});
          await this.toaster.showSaveSuccessToast();

        } catch (e) {
          console.log(e);
          await this.toaster.showErrorToast(e.code);
        }
  }

  async cancelRequest(row: any) {
    const alert = await this.alertCtrl.create({
      cssClass: 'bidAlert',
      header: this.trans.instant('cancelRequestAlertHeader'),
      subHeader: this.trans.instant('cancelRequestAlertSub'),
      buttons: [
        {
          text: this.trans.instant('cancelRequestAlertButtonText'),
          handler: async ({cancelReason}) => {
            try {
              if (!cancelReason) {
                throw new Error('enterCancelReason')
              }
              await Parse.Cloud.run('cancelRequest', {requestId: row.id, cancelReason: cancelReason});
              await this.toaster.showSuccessToast('bidSuccessful')
            } catch (e) {
              console.log(e);
              await this.toaster.showErrorToast(e.message);
            }
          }
        },
      ],
      inputs: [
        {
          name: 'cancelReason',
          type: 'textarea',
          placeholder: this.trans.instant('cancelReasonTextAreaPlaceholder'),
        },
      ],
    });

    await alert.present();
  }
}
