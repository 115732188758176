import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: 'dateSinceWhen'
})
export class DateSinceWhenPipe implements PipeTransform {

  constructor(private trans: TranslateService) {
  }
  transform(date1: any, date2: any): any {
    // console.log('Date 1 : ', date1, 'Date 2 ', date2)
    const minutesSince = Math.round(Math.abs(date1 - date2) / (60* 1000));
    switch (true) {
      case minutesSince < 60:
        if(this.trans.currentLang === 'en') {
          return `<strong>${minutesSince}</strong> minutes ago`;
        }
        return `Il y a <strong>${minutesSince}</strong> minutes`;

      case minutesSince >= 60 && minutesSince < 1440 :
        if(this.trans.currentLang === 'en') {
          return `<strong>${Math.round(minutesSince / 60)}</strong> hours ago`;
        }
        return `Il y a <strong>${Math.round(minutesSince / 60)}</strong> heures`;

      case minutesSince >= 1440 :
        if(this.trans.currentLang === 'en') {
          return `<strong>${Math.round(minutesSince / 1440)}</strong> days ago`;
        }
        return `Il y a <strong>${Math.round(minutesSince / 1440)}</strong> jours`;
    }
  }

}
