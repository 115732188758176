import * as Parse from "parse";
import {BehaviorSubject} from "rxjs";

export class LiveArray {

  onEnter = new BehaviorSubject<Parse.Object>(null);
  onLeave = new BehaviorSubject<Parse.Object>(null);
  onCreate = new BehaviorSubject<Parse.Object>(null);
  onUpdate = new BehaviorSubject<Parse.Object>(null);
  onDelete = new BehaviorSubject<Parse.Object>(null);

  results: Parse.Object[] = [];

  private liveSub: Parse.LiveQuerySubscription;


  constructor(public query: Parse.Query) {}

  private async subscribe() {
    if (this.liveSub) {
      this.liveSub.unsubscribe();
    }
    this.liveSub = await this.query.subscribe();
    this.liveSub.on('enter', (o) => {
      console.log('enter called');
      this.results.push(o);
      this.onEnter.next(o);
    })
    this.liveSub.on('leave', (o) => {
      console.log('leave Called');
      const index = this.results.findIndex(obj => obj.id === o.id);
      if (index !== -1) {
        this.results.splice(index, 1);
        this.onLeave.next(o);
      }
    })
    this.liveSub.on('create', (o) => {
      this.results.push(o);
      this.onCreate.next(o);
    })
    this.liveSub.on('update', (o) => {
      console.log('updated called');
      // const index = this.results.findIndex(obj => obj.id === o.id);
      // if (index !== -1) {
      //   console.log(o);
        // this.results[index] = o;
        this.onUpdate.next(o);
      // }
    })
    this.liveSub.on('delete', (o) => {
      const index = this.results.findIndex(obj => obj.id === o.id);
      if (index !== -1) {
        this.results.splice(index, 1);
        this.onDelete.next(o);
      }
    })
  }

  async getResults() {
    await this.subscribe();
    this.results = await this.query.find();
  }


}
