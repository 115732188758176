import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ControlContainer, FormGroupDirective} from "@angular/forms";
import {IonInput} from "@ionic/angular";
import { } from 'googlemaps';

@Component({
  selector: 'app-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.scss'],
  viewProviders: [{useExisting: FormGroupDirective, provide: ControlContainer}]
})
export class AutocompleteInputComponent  implements OnInit, AfterViewInit {

  @Output('placeChanged') placeChanged = new EventEmitter<google.maps.places.PlaceResult>
  @Input({required: true}) formcontrolname: string;
  @ViewChild('input', {static: true}) input: IonInput;

  private bounds = new google.maps.LatLng(48.3910229, -71.0274266)
  private acOptions = {
    location: this.bounds,
    radius: 150000,  // (in meters; this is 150Km)
    types: [],
  };

  constructor() {

  }

  async ngOnInit() {

  }

  async ngAfterViewInit() {
    const natEl = await this.input.getInputElement();
    const autocomplete = new google.maps.places.Autocomplete(natEl, this.acOptions)
    autocomplete.addListener('place_changed', () => {
      this.placeChanged.emit(autocomplete.getPlace());
    })
  }

}
