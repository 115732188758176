export class CoordsUrlParam {
  ipPath: string;
  coordLoad: string;
  coordUnload: string;
  endUrlParam: string;



  public buildPath(val: CoordsUrlParam) {
    let fullPath = "";
    for (let values of Object.values(val)) {
      fullPath += values;

    }

    return fullPath;
  }

  constructor() {
  }

}
