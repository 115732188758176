// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-equipment-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.add-equipment-container .add-equipment-input-container {
  width: 100%;
  margin-bottom: 1rem;
}

.types-cat-card {
  width: 200px;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1490196078);
  transition: all ease 0.5s;
  cursor: pointer;
}
.types-cat-card:hover {
  transform: scale(1.05);
}

.types-cat-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/add-equipments-modal/add-equipments-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AACF;AACE;EACE,WAAA;EACA,mBAAA;AACJ;;AAKA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,mDAAA;EACA,yBAAA;EACA,eAAA;AAFF;AAIE;EACE,sBAAA;AAFJ;;AAOA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,6BAAA;AAJF","sourcesContent":[".add-equipment-container {\r\n  width: 50%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n\r\n  .add-equipment-input-container {\r\n    width: 100%;\r\n    margin-bottom: 1rem;\r\n  }\r\n\r\n}\r\n\r\n\r\n.types-cat-card {\r\n  width: 200px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  padding: 2rem;\r\n  margin-top: 1rem;\r\n  margin-bottom: 1rem;\r\n  box-shadow: 2px 2px 5px #00000026;\r\n  transition: all ease 0.5s;\r\n  cursor: pointer;\r\n\r\n  &:hover {\r\n    transform: scale(1.05);\r\n  }\r\n\r\n}\r\n\r\n.types-cat-container {\r\n  width: 100%;\r\n  display: flex;\r\n  flex-direction: row;\r\n  flex-wrap: wrap;\r\n  justify-content: space-around;\r\n\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
