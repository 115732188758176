import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {NgSignaturePadOptions, SignaturePadComponent} from "@almothafar/angular-signature-pad";
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-signature',
  templateUrl: './signature-modal.component.html',
  styleUrls: ['./signature-modal.component.scss'],
})
export class SignatureModalComponent implements OnInit, AfterViewInit {

  @ViewChild('signature')
  public signaturePad: SignaturePadComponent;

  @ViewChild('signatureModalContent', {static: false})
  public signatureModalContent;

  public signaturePadOptions: NgSignaturePadOptions = {
    minWidth: 5,
    canvasWidth: 300,
    canvasHeight: 700
  };

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    console.log(this.signatureModalContent);
    console.log(this.signatureModalContent.el.clientWidth);
  }

  drawComplete(event: MouseEvent | Touch) {
    // will be notified of szimek/signature_pad's onEnd event
    console.log('Completed drawing', event);
    console.log(this.signaturePad.toDataURL());
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  contentResized() {
    console.log(this.signatureModalContent.el.clientWidth);
  }
}
