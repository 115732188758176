import {Component, OnInit} from '@angular/core';
import {RouteDataService} from "./providers/route-data.service";
import {Router} from "@angular/router";
import {ParseService} from "./providers/parse.service";
import {TranslatorService} from "./providers/translator.service";
import {CurrentUserService} from "./providers/current-user.service";
import {SwPush} from "@angular/service-worker";
import {SwUpdaterService} from "./providers/sw-updater.service";
import {registerLocaleData} from "@angular/common";
import localeFr from '@angular/common/locales/fr-CA';
import {ModalService} from "./providers/modal.service";
import {take} from "rxjs";
import {IonNav} from "@ionic/angular";


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent implements OnInit{


  constructor(
    private push: SwPush,
    private routeData: RouteDataService,
    private route: Router,
    private parse: ParseService,
    private cus: CurrentUserService,
    private translator: TranslatorService,
    private swUpdater: SwUpdaterService,
    private modalService: ModalService,
  ) {

  }

  async ngOnInit() {
    this.swUpdater.checkForUpdate();
    registerLocaleData(localeFr, 'fr');
    // this.cus.currentUser.pipe(take(1)).subscribe(user => {
    //   if (user && user.get('passwordChangeNeeded')) {
    //   }
    // })
  }



}
