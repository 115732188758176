import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy, IonNav} from '@ionic/angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {RouteDataService} from "./providers/route-data.service";
import {FlatpickrModule} from "angularx-flatpickr";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ParseService} from "./providers/parse.service";
import {TranslatorService} from "./providers/translator.service";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {MainModule} from "./main/main.module";
import {LoadingService} from "./providers/loading.service";
import {AuthModule} from "./auth/auth.module";
import {LottieModule} from "ngx-lottie";
import {CurrentUserService} from "./providers/current-user.service";
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {SwUpdaterService} from "./providers/sw-updater.service";
import { JobPriorityColorPipe } from './providers/job-priority-color.pipe';
import {GlobalErrorHandler} from "./Utilities/global-errors-handler";
import {HttpLoadingInterceptor} from "./Utilities/http-loading.interceptors";
import {APP_BASE_HREF} from "@angular/common";
import {TourIonPopoverModule} from "ngx-ui-tour-ionic";



export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function playerFactory() {
  return import('lottie-web');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    TourIonPopoverModule,
    IonicModule.forRoot(),

    LottieModule.forRoot({
      player: playerFactory
    }),
    AuthModule,
    MainModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      },
      defaultLanguage: 'fr',
      isolate: false
    }),
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    FlatpickrModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),

  ],
  providers: [
    SwUpdaterService,
    CurrentUserService,
    LoadingService,
    RouteDataService,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      // processes all errors
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    ParseService,
    TranslatorService,
    TranslateService,
  ],
  bootstrap: [AppComponent],
    exports: []
})
export class AppModule {
}
