import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseToObjects'
})
export class ParseToObjectsPipe implements PipeTransform {

  transform(value: Parse.Object[], ...args: unknown[]): any[] {
    console.log(value);
    return value.map(o => {return {id: o.id, ...o.attributes}});
  }

}
