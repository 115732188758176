import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {ModalService} from "../../../providers/modal.service";
import {FilesManagerModalComponent} from "../files-manager-modal/files-manager-modal.component";
import {SwiperOptions} from "swiper";
import {EquipmentsType} from "../../../Entities/EquipmentsType";
import {TranslatorService} from "../../../providers/translator.service";
import * as Parse from 'parse';
import {LoadingService} from "../../../providers/loading.service";
import {ToasterService} from "../../../providers/toaster.service";
import * as _ from 'lodash';
import {SwiperComponent} from "swiper/angular";

@Component({
  selector: 'app-add-equipments-modal',
  templateUrl: './add-equipments-modal.component.html',
  styleUrls: ['./add-equipments-modal.component.scss'],
})
export class AddEquipmentsModalComponent implements OnInit {

  @Input() mode: string;
  @Input() equipmentToModify: Parse.Object;
  @ViewChild('swiper', {static: true}) swiper?: SwiperComponent;


  slideOpts: SwiperOptions = {
    slidesPerView: 1, allowTouchMove: false, autoHeight: false
  }

  newEquipment = new Parse.Object('Equipments');

  imagesToAdd: Parse.Object[] = [];
  equipmentTypes: Parse.Object[];


  selectedTypeCategory: string;

  name: string;
  type: Parse.Object;

  selectedImageSliderOpts: SwiperOptions = {
    slidesPerView: 2.5, allowTouchMove: true, autoHeight: false
  };
  quantity = 1;

  constructor(private modalController: ModalController, public trans: TranslatorService, private loading: LoadingService, private toaster: ToasterService) { }

  async ngOnInit() {
    this.equipmentTypes = await new EquipmentsType().query.ascending(`name_${this.trans.currentLang}`).find();

    if (this.mode === 'modify') {
      this.name = this.equipmentToModify.get('name');
      this.quantity = this.equipmentToModify.get('quantity');
      this.imagesToAdd = this.equipmentToModify.get('images');
      this.newEquipment = this.equipmentToModify;
    }



  }

  asignImage(e) {
    this.imagesToAdd.push(e);
  }

  closeModal() {
    this.modalController.dismiss();
  }

  selectType($event: any) {
    this.type = $event;
  }

  async addEquipment() {
    try {
      await this.loading.presentLoading();
      const savedEquipment = await this.newEquipment.save({quantity: this.quantity, type: this.type, images: this.imagesToAdd, name: this.name});
      await this.toaster.showSaveSuccessToast();
      await this.loading.removeLoading();
      await this.modalController.dismiss(savedEquipment);
    } catch (e) {
      await this.toaster.showUnexpectedErrorToast(e.message);
      await  this.loading.removeLoading();
    }
  }

  removeImage(i: number) {
    this.imagesToAdd.splice(i, 1);
  }

  selectTypeCat(catName: string) {
    this.equipmentTypes = this.equipmentTypes.filter((type) => type.get('category_en').toLowerCase() === catName);
    this.swiper.swiperRef.slideNext(650);
  }
}
