// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggleContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.toggleContainer ion-item {
  width: 33%;
}

@media (max-width: 992px) {
  .toggleContainer ion-item {
    width: 50%;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/notification-settings-list/notification-settings-list.component.scss","webpack://./src/mixins/responsive.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AADF;AAGE;EACE,UAAA;AADJ;;ACII;EDIA;IACE,UAAA;EAJJ;AACF","sourcesContent":["@import 'responsive';\r\n\r\n.toggleContainer {\r\n  display: flex;\r\n  flex-direction: row;\r\n  flex-wrap: wrap;\r\n\r\n  ion-item {\r\n    width: 33%;\r\n  }\r\n\r\n}\r\n\r\n@include screen('lg') {\r\n  .toggleContainer {\r\n    ion-item {\r\n      width: 50%;\r\n    }\r\n  }\r\n}\r\n","$breakpoints: (\r\n  'sm':  576px,\r\n  'md': 768px,\r\n  'lg':  992px,\r\n  'xl':  1200px\r\n) !default;\r\n\r\n@mixin screen($breakpoint) {\r\n  // If the key exists in the map\r\n  @if map-has-key($breakpoints, $breakpoint) {\r\n    // Prints a media query based on the value\r\n    @media (max-width: map-get($breakpoints, $breakpoint)) {\r\n      @content;\r\n    }\r\n  }\r\n\r\n    // If the key doesn't exist in the map\r\n  @else {\r\n    @warn \"Unfortunately, no value could be retrieved from `#{$breakpoint}`. \"\r\n        + \"Available breakpoints are: #{map-keys($breakpoints)}.\";\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
