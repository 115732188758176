import {Component, Input, OnInit} from '@angular/core';
import * as Parse from "parse";
import {ModalController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import {ToasterService} from "../../../providers/toaster.service";
import {CurrentUserService} from "../../../providers/current-user.service";

@Component({
  selector: 'app-notification-settings-list',
  templateUrl: './notification-settings-list.component.html',
  styleUrls: ['./notification-settings-list.component.scss'],
})
export class NotificationSettingsListComponent implements OnInit {

  @Input('owner') owner: Parse.User;
  public jobTypes: Parse.Object[];
  public notificationSettings: Parse.Object[];

  public adminNotificationTypes: Parse.Object[];
  public adminNotificationSettings: Parse.Object[];

  constructor(public cus: CurrentUserService, private modalController: ModalController, public trans: TranslateService, private toaster: ToasterService) { }

  async ngOnInit() {
    this.jobTypes = await new Parse.Query('JobsTypes').findAll();
    this.notificationSettings = await new Parse.Query('JobsTypesNotificationSettings')
      .equalTo('owner', this.owner)
      .containedIn('jobType', this.jobTypes)
      .find();
    this.createSettings();

    if (this.cus.isRole('Admin')) {
      this.adminNotificationTypes = await new Parse.Query('AdminNotificationTypes').findAll();
      this.adminNotificationSettings = await new Parse.Query('AdminNotificationSettings')
        .equalTo('owner', this.owner)
        .containedIn('notificationType', this.adminNotificationTypes)
        .find();
      this.createAdminSettings();
    }

  }

  // Create settings object if they don't exist. For example, will be created when someone opens the notifications parameters for the first time.
  async createSettings() {
    for (const j of this.jobTypes) {
      const notificationSetting = this.notificationSettings.find(s => s.attributes.jobType.id === j.id);
      if (notificationSetting) return;
      try {
        const newSetting = await new Parse.Object('JobsTypesNotificationSettings')
          .save({owner: this.owner, jobType: j, allowed: true, notificationType: 'email'});
        this.notificationSettings.push(newSetting);
      } catch (e) {
        throw new Error(e);
      }
    }
  }

  async onSettingToggle($event: CustomEvent, setting: Parse.Object) {
    const checked = $event.detail.checked;
    try {
      await setting.save({allowed: checked});
    } catch (e) {
      this.toaster.showSaveErrorToast();
    }
  }

  async createAdminSettings() {
    for (const t of this.adminNotificationTypes) {
      const adminNotificationSetting = this.adminNotificationSettings.find(s => s.attributes.jobType.id === t.id);
      if (adminNotificationSetting) return;
      try {
        const newSetting = await new Parse.Object('AdminNotificationSettings')
          .save({owner: this.owner, notificationType: t, allowed: true});
        this.adminNotificationSettings.push(newSetting);
      } catch (e) {
        throw new Error(e);
      }
    }
  }
}
