import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import {Observable} from "rxjs";
import * as Parse from 'parse';
import {CurrentUserService} from "./current-user.service";


@Injectable({
  providedIn: 'root'
})
export class GuardService  {



  constructor(private router: Router, private cus: CurrentUserService) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log('we are currently in GUARD.SERVIC')
    return true;
    // return new Promise(async (res, rej) => {
    //   setTimeout(() => {
    //     const user = this.cus.currentUser.getValue();
    //     if (user) {
    //       res(true);
    //     } else {
    //       this.router.navigateByUrl('/login');
    //       res(false);
    //     }
    //   }, 300)
    // })
  }
}
