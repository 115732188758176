import {AfterViewInit, Component, OnInit} from '@angular/core';
import {AnimationOptions} from "ngx-lottie";
import * as Parse from 'parse';
import {ActivatedRoute, Router} from "@angular/router";
import {LoadingService} from "../../providers/loading.service";
import {ToasterService} from "../../providers/toaster.service";

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent implements OnInit, AfterViewInit {
  options: AnimationOptions = {
    path: './assets/animations/email.json'
  }

  user = Parse.User.current();

  buttonAction: { providerLink: string, providerName: string };
  verificationCode: any;

  constructor(private route: ActivatedRoute, private router: Router, private loading: LoadingService, private toaster: ToasterService) {
  }

  async ngAfterViewInit() {

  }

  async ngOnInit() {

    if (this.user.get('emailVerified')) {
      this.router.navigateByUrl('/dashboard');
    }

    this.route.queryParams.subscribe(p => {
      this.verificationCode = p.code;
    });

    const emailDomain = this.user.getEmail().substring(this.user.getEmail().lastIndexOf('@') + 1);
    switch (emailDomain) {
      case 'gmail.com':
        this.buttonAction = {providerLink: 'https://gmail.com', providerName: 'Gmail'};
        break;

      case 'yahoo.com' || 'yahoo.ca':
        this.buttonAction = {providerLink: 'https://login.yahoo.com/', providerName: 'Yahoo'};
        break;

      case 'hotmail.com' || 'hotmail.ca' || 'hotmail.fr' || 'hotmail.us' :
        this.buttonAction = {providerLink: 'https://login.live.com/login.srf', providerName: 'Hotmail'};
        break;

      default:
        this.buttonAction = {providerLink: null, providerName: 'Hotmail'};
    }

  }

  async verifyEmail() {
    try {
      await Parse.Cloud.run('verifyEmail', {code: this.verificationCode})
      this.router.navigateByUrl('/make-profile');
    } catch (e) {
      await this.toaster.showErrorToast(e.message);
    }
  }

  openEmailProvider() {
    window.open(this.buttonAction.providerLink);
  }

  async resendEmail() {
    // this.user.
    // try {
    //   await Parse.Cloud.run('resendEmailVerification');
    //   this.toaster.showSuccessToast('emailSent');
    // } catch(e) {
    //   if (e.message === 'wait5Minutes') {
    //     this.toaster.showErrorToast(e.message);
    //   } else {
    //     this.toaster.showUnexpectedErrorToast(e.message);
    //   }
    // }
  }
}
