import {AfterViewInit, Component, HostListener, OnInit, ViewChild} from '@angular/core';
import SwiperCore, {EffectCube, SwiperOptions, Virtual} from 'swiper';
import {SwiperComponent} from "swiper/angular";
import {AnimationOptions} from "ngx-lottie";
import {Profile} from "../../Entities/Profile";
import {} from 'googlemaps';

SwiperCore.use([Virtual, EffectCube]);
import * as Parse from 'parse';
import {ParseUtilities} from "../../Utilities/parse.utilities";
import {TranslatorService} from "../../providers/translator.service";
import {CurrentUserService} from "../../providers/current-user.service";
import {IonInput, IonTextarea} from "@ionic/angular";
import {UntypedFormControl} from "@angular/forms";
import {LoadingService} from "../../providers/loading.service";
import {Router} from "@angular/router";
import {ToasterService} from "../../providers/toaster.service";


@Component({
  selector: 'app-make-profile',
  templateUrl: './make-profile.component.html',
  styleUrls: ['./make-profile.component.scss'],
})
export class MakeProfileComponent implements OnInit, AfterViewInit {

  currentUserRoles: Parse.Role[];
  roles: Parse.Role[];
  profile: Parse.Object;
  roleChangeDisabled = false;

  @ViewChild('swiper', {static: true}) swiper?: SwiperComponent;
  @ViewChild('terms', {static: false}) terms: IonTextarea;
  @ViewChild('autocompletefield', {static: false}) autocompletefield: IonInput;
  @ViewChild('autocompletefield2', {static: false}) autocompletefield2: IonInput;
  @ViewChild('phoneNumberControl', {static: false}) phoneNumberControl: UntypedFormControl;

  slideOpts: SwiperOptions = {
    slidesPerView: 1, allowTouchMove: false, autoHeight: false, keyboard: false
  }

  // ANIMATION OPTIONS

  step1LottieOptions: AnimationOptions = {
    path: './assets/animations/67352-profile-creation-loader.json'
  }
  phoneVerificationLottieOptions: AnimationOptions = {
    path: './assets/animations/phone_verify.json'
  }

  smsPendingLottieOptions: AnimationOptions = {
    path: './assets/animations/smsPending.json'
  }

  checkLottieOptions: AnimationOptions = {
    path: './assets/animations/check.json'
  }

  successLottieOptions: AnimationOptions = {
    path: './assets/animations/success.json'
  }


  // END ANIMATION OPTIONS

  // PHONE NUMBER TEMPLATE FORM

  phoneNumber;

  // END PHONE NUMBER TEMPLATE FORM


  // VERIFICATION TEMPLATE FORM

  verificationCode = '';
  verificationSent = false;

  // END VERIFICATION TEMPLATE FORM

  first_name: string;
  last_name: string;
  address: string;

  constructor(private router: Router, public trans: TranslatorService, public current: CurrentUserService, private loading: LoadingService, private toast: ToasterService) {
  }

  @HostListener('document:keydown', ['$event']) documentClickEvent($event: MouseEvent) {
    // @ts-ignore comment
    if ($event.key === 'F13') {
      $event.preventDefault();
    }
  }

  async ngOnInit() {
    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    // document.querySelector('ion-input').addEventListener('keydown', function (e) {
    //   console.log(e);
    // });
    try {
      await this.current.getCurrentUser();
      this.roles = await ParseUtilities.getRoles();
      this.current.currentUserRoles.subscribe((v) => {
        this.currentUserRoles = v;
        if (this.currentUserRoles.find(r => r.get('name') === 'Client')) {
          this.roleChangeDisabled = true;
        }
      });
      const p = await new Profile().getUserProfile(Parse.User.current());
      if (!p) {
        const profile = new Profile();
        this.profile = await profile.save({});
      } else {
        this.profile = p;
        this.goToProfileStep();
      }
    } catch (e) {
      console.log(e);
    }
  }

  async ngAfterViewInit() {

  }

  async setAutoCompleteField() {
    const bounds = new google.maps.LatLng(48.3910229, -71.0274266)
    const acOptions = {
      location: bounds,
      radius: 150000,  // (in meters; this is 150Km)
      types: [],
    };
    const natEl = await this.autocompletefield.getInputElement();
    const natEl2 = await this.autocompletefield2.getInputElement();
    if (natEl) {
      const autocomplete = new google.maps.places.Autocomplete(natEl, acOptions)
      autocomplete.addListener('place_changed', () => {
        this.saveAddress(autocomplete.getPlace())
      })
    }
    if (natEl2) {
      const autocomplete2 = new google.maps.places.Autocomplete(natEl2, acOptions)
      autocomplete2.addListener('place_changed', () => {
        this.saveBillingAddress(autocomplete2.getPlace())
      })
    }
  }

  next() {
    this.swiper.swiperRef.slideNext(650);
  }

  back() {
    this.swiper.swiperRef.slidePrev(650);
  }

  goToIndex(n: number) {
    this.swiper.swiperRef.slideTo(n, 650);
  }


  async goToProfileStep() {
    console.log(this.currentUserRoles);
    this.setAutoCompleteField();
    switch (true) {
      // Si l'utilistaeur actuel n'est pas dans un des roles (Client, Entreprise ou Transporteur)
      // On le redirige sur l'étape 0
      case (!this.roles.some((r) => this.currentUserRoles.map(v => v.id).includes(r.id))):
        this.goToIndex(0);
        break;

      case (!this.current.currentUser?.getValue()?.get('terms_accepted')):
        this.goToIndex(1);
        break;

      case (!this.profile.get('phone_number')):
        this.goToIndex(2);
        break;

      case (!this.profile.get('phone_verified')):
        this.goToIndex(3);
        break;

      case (!this.profile.get('address') || !this.profile.get('first_name') || !this.profile.get('last_name')):
        this.goToIndex(4);
        break;

      case (this.current.isRole('Client') || this.current.isRole('Transporteur')):
        this.goToIndex(5);
        break;


    }

  }

  async changeRole($event: any) {
    const roleId = $event.detail.value;
    try {
      await Parse.Cloud.run('addRole', {id: roleId});
      await this.current.getCurrentUser();
      await this.current.fetchUserRoles();
      this.goToProfileStep();
    } catch (e) {
      console.log(e);
    }
  }


  profileHasValues() {
    switch (true) {
      case (this.swiper.swiperRef?.activeIndex === 0 && this.currentUserRoles?.length > 0):
        return true;

      case (this.swiper.swiperRef?.activeIndex === 1 && this.current.currentUser?.getValue()?.get('terms_accepted')):
        return true;

      case (this.swiper.swiperRef?.activeIndex === 2 && this.profile.get('phone_number')?.length > 0):
        return true;

      case (this.swiper.swiperRef?.activeIndex === 3 && this.profile.get('phone_verified')):
        return true;

      case (this.swiper.swiperRef?.activeIndex === 4 && this.profile.get('address')?.length > 0 && this.profile.get('first_name')?.length > 0 && this.profile.get('last_name')?.length > 0):
        return true;

      default:
        return false;
    }
  }

  async acceptConditions($event) {
    Parse.User.current().set('terms_accepted', $event.detail.checked);
    try {
      await Parse.User.current().save();
      this.current.getCurrentUser();
      this.goToProfileStep();
    } catch (e) {
      console.log(e);
    }
  }

  async verifyPhone() {
    if (this.phoneNumberControl.valid && this.phoneNumberControl.touched) {
      try {
        await Parse.Cloud.run('sendSmsCode', {phone: `+1${this.phoneNumber}`});
        await this.profile.fetch();
        this.goToProfileStep();
      } catch (e) {
        await this.toast.showErrorToast(e.message);
      }
    }
  }

  async checkVerificationCode() {
    if (this.verificationCode.length === 6 && !this.verificationSent) {
      this.verificationSent = true;
      await this.loading.presentLoading();
      try {
        await Parse.Cloud.run('verifyCode', {to: this.profile.get('phone_number'), code: this.verificationCode});
        this.verificationSent = false;
        await this.profile.fetch();
        this.verificationCode = '';
        await this.loading.removeLoading();
        this.goToProfileStep();
      } catch (e) {
        this.toast.showErrorToast(e.message);
        this.verificationCode = '';
        this.verificationSent = false;
        await this.loading.removeLoading();
      }
    }
  }

  logEvent(ev) {
    console.log(ev);
  }

  async saveAddress(address) {
    try {
      await this.profile.save({
        address: address?.formatted_address,
      });
      await this.profile.fetch();
    } catch (e) {
      console.log(e);
    }
  }

  async saveBillingAddress(address) {
    try {
      await this.profile.save({
        billingAddress: address?.formatted_address,
      });
      await this.profile.fetch();
    } catch (e) {
      console.log(e);
    }
  }

  async savePersonalInfos() {
    try {
      await this.profile.save({
        first_name: this.first_name,
        last_name: this.last_name,
      });
      await this.profile.fetch();
    } catch (e) {
      console.log(e);
    }
  }


  async completeProfile() {
    const user = this.current.currentUser.value;
    user.set('profileCompleted', true);
    try {
      await this.loading.presentLoading();
      await user.save();
      await this.loading.removeLoading();
      await this.router.navigateByUrl('/');
    } catch (e) {
      console.log(e);
      await this.loading.removeLoading();
    }
  }

  changeNumber() {
    this.goToIndex(2);
  }
}
