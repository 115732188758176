import {Component, OnInit} from '@angular/core';
import {LoadingService} from "../../../providers/loading.service";
import {ToasterService} from "../../../providers/toaster.service";
import {Suggestions} from "../../../Entities/Suggestions";
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-suggestion-modal',
  templateUrl: './suggestion-modal.component.html',
  styleUrls: ['./suggestion-modal.component.scss'],
})
export class SuggestionModalComponent implements OnInit {

  message: string;
  suggestion: Parse.Object;


  constructor(private loading: LoadingService, private toaster: ToasterService, private modal: ModalController) {
  }

  async ngOnInit() {
    this.suggestion = new Suggestions();
  }

  async sendSuggestion() {
    await this.loading.presentLoading();
    try {
      await this.suggestion.save({message: this.message});
      await this.toaster.showSuccessToast('suggestionSent');
      await this.loading.removeLoading();
      await this.modal.dismiss();
    } catch (e) {
      await this.toaster.showUnexpectedErrorToast(e.message);
      await this.loading.removeLoading();
    }
  }

  closeModal() {
    this.modal.dismiss();
  }
}
