import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  currentUser: Parse.User;

  constructor(private router: Router) {
    this.currentUser = Parse.User.current();
  }

  public logOut() {
    if (this.currentUser) {
      Parse.User.logOut().then(() => {
        this.currentUser = Parse.User.current();
        this.router.navigateByUrl('login');
      })
    }
  }

}
