import { Injectable } from '@angular/core';
import {ToastController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(private toastController: ToastController, private trans: TranslateService) { }

  async showSaveSuccessToast() {
    const toast = await this.toastController.create({
      color: 'success',
      message: this.trans.instant('saveSuccessMessage'),
      duration: 3000,
      position: 'top'
    });
    toast.present();
  }

  async showSaveErrorToast() {
    const toast = await this.toastController.create({
      color: 'danger',
      message: this.trans.instant('saveErrorMessage'),
      duration: 3000,
      position: 'top'
    });
    toast.present();
  }

  async showUnexpectedErrorToast(key: string) {
    throw new Error(key);
    const toast = await this.toastController.create({
      color: 'danger',
      message: this.trans.instant('unexpectedErrorMessage'),
      duration: 3000,
      position: 'top'
    });
    toast.present();
  }

  async showSuccessToast(key: string) {
    const toast = await this.toastController.create({
      color: 'success',
      message: this.trans.instant(key),
      duration: 3000,
      position: 'top'
    });
    toast.present();
  }

  async showErrorToast(key: string) {
    const toast = await this.toastController.create({
      color: 'danger',
      message: this.trans.instant(key) === key ? this.trans.instant('unexpectedErrorMessage') : this.trans.instant(key),
      duration: 3000,
      position: 'top'
    });
    toast.present();
    throw new Error(key);
  }
}


