import * as Parse from 'parse';
import {BehaviorSubject, Observable} from "rxjs";

export class ParseUtilities {

  static getRandomString(length) {
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let result = ''
    for (let i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length))
    }
    return result;
  }


  static async getUserRoles(user: Parse.User): Promise<Parse.Role[]> {
    const query = await new Parse.Query(Parse.Role).equalTo('users', user).find();
    return query;
  }

  static async getRoles(): Promise<Parse.Role[]> {
    const query = await new Parse.Query(Parse.Role).find();

    return query;
  }



  static async liveArray(query: Parse.Query, callbacks?: {onEnter: BehaviorSubject<any>}): Promise<Parse.Object[]> {
    callbacks.onEnter = new BehaviorSubject<any>(null);
    return new Promise((async (resolve, reject) => {
      try {
        const liveSub: Parse.LiveQuerySubscription = await query.subscribe();
        const allObjects: Parse.Object[] = await query.find();
        liveSub.on('enter', (o) => {
          allObjects.push(o);
          callbacks.onEnter.next(o);
        })
        liveSub.on('leave', (o) => {
          const index = allObjects.findIndex(obj => obj.id === o.id);
          if (index !== -1) {
            allObjects.splice(index, 1);
          }
        })
        liveSub.on('create', (o) => {
          allObjects.push(o);
        })
        liveSub.on('update', (o) => {
          const index = allObjects.findIndex(obj => obj.id === o.id);
          if (index !== -1) {
            allObjects[index] = o;
          }
        })
        liveSub.on('delete', (o) => {
          const index = allObjects.findIndex(obj => obj.id === o.id);
          if (index !== -1) {
            allObjects.splice(index, 1);
          }
        })
        resolve(allObjects);
      } catch (e) {
        reject(e);
      }
    }))

  }


}


