import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import * as Parse from "parse";
import {Uploads} from "../../../Entities/Uploads";
import {LoadingService} from "../../../providers/loading.service";
import {ToasterService} from "../../../providers/toaster.service";

@Component({
  selector: 'app-file-upload-button',
  templateUrl: './file-upload-button.component.html',
  styleUrls: ['./file-upload-button.component.scss'],
})
export class FileUploadButtonComponent implements OnInit {

  @Output() onFileUploaded = new EventEmitter<Parse.Object>();

  constructor(private loading: LoadingService, private toaster: ToasterService) { }

  ngOnInit() {}

  async onFileChosen(e) {
    const selectedFile = e.target.files[0];
    const selectedFileName = selectedFile.name;
    const fileBase64 = await this.fileToBase64(e.target.files[0]);
    const parseFile = new Parse.File(selectedFileName, {base64: fileBase64},);
    try {
      await this.loading.presentLoading();
      const newUpload = await new Uploads().save({fileType: selectedFile.type, file: parseFile});
      this.onFileUploaded.emit(newUpload);
      await this.loading.removeLoading();
    } catch (error) {
      await this.toaster.showUnexpectedErrorToast(error.message);
      await this.loading.removeLoading();
    }
  }

  async fileToBase64(file: File): Promise<any> {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => res(reader.result);
      reader.onerror = error => rej(error);
    })

  }

}
