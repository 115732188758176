import {ParseObject} from "./Parse.object";

export class Profile extends ParseObject {

  constructor() {
    super('Profile');
  }

  async getUserProfile(user: Parse.User): Promise<Parse.Object> {
    try {
      const query = this.query;
     // console.log(this.query)
      query.equalTo('owner', user);

      const [profile] = await query.find();
      return profile;
    } catch(e) {
      console.log(e);
    }

  }


}
