import { Component, OnInit } from '@angular/core';
import * as Parse from 'parse';
import {Router} from "@angular/router";
import {CurrentUserService} from "../../../providers/current-user.service";

@Component({
  selector: 'app-logout-button',
  templateUrl: './logout-button.component.html',
  styleUrls: ['./logout-button.component.scss'],
})
export class LogoutButtonComponent implements OnInit {

  currentUser;

  constructor(private router: Router, private current: CurrentUserService) {

  }

  ngOnInit() {
    this.currentUser = Parse.User.current;
  }

  logOut() {
    Parse.User.logOut().then(async () => {
      this.current.currentUser.next(undefined);
      localStorage.clear();
      await this.router.navigateByUrl('/login');
      window.location.reload();
    })
  }
}
