import {Component, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalController, ToastController} from "@ionic/angular";
import {ProfileModalHomeComponent} from "./profile-modal-home/profile-modal-home.component";
import {ProfileEmailChangeComponent} from "./profile-email-change/profile-email-change.component";
import {ProfileInsuranceProofsComponent} from "./profile-insurance-proofs/profile-insurance-proofs.component";
import {CurrentUserService} from "../../../providers/current-user.service";

@Component({
  selector: 'app-profile-modal',
  templateUrl: './profile-modal.component.html',
  styleUrls: ['./profile-modal.component.scss'],
})
export class ProfileModalComponent implements OnInit, OnDestroy {

  @ViewChild('modalNav',{static: true}) modalNav: HTMLIonNavElement;
  @Input() navigateToInsurance: boolean;
  //onInsuranceProof: boolean = false;


  constructor(private modalController: ModalController, public user: CurrentUserService) {
  }

  async ngOnInit() {
    await this.modalNav.setRoot(ProfileModalHomeComponent);
    if (this.navigateToInsurance) {
      this.goToInsuranceProofs();
    }

  }

  @HostListener('unloaded')
  ngOnDestroy() {
    console.log('Items destroyed');
  }
  async gotoEmailChange() {
    await this.modalNav.push(ProfileEmailChangeComponent);
  }

  async goToInsuranceProofs() {
    // this.onInsuranceProof = true;
    console.log(await this.modalNav.getActive());
    await this.modalNav.push(ProfileInsuranceProofsComponent);
  }

  async backToRoot() {
    // this.onInsuranceProof = false;
    await this.modalNav.popToRoot();
  }
  closeModal() {
    this.modalController.dismiss();
  }



}
