import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";
import {RouterModule} from "@angular/router";
import {IonicModule, NavParams} from "@ionic/angular";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FlatpickrModule} from "angularx-flatpickr";
import {LangSwitcherComponent} from "./components/langswitcher/langswitcher.component";
import {LogoutButtonComponent} from "./components/logout-button/logout-button.component";
import {UserService} from "../providers/user.service";
import {SwiperModule} from "swiper/angular";
import {ProfileModalComponent} from "./components/profile-modal/profile-modal.component";
import {ToasterService} from "../providers/toaster.service";
import {ProfileModalHomeComponent} from "./components/profile-modal/profile-modal-home/profile-modal-home.component";
import {ProfileEmailChangeComponent} from "./components/profile-modal/profile-email-change/profile-email-change.component";
import {ModalService} from "../providers/modal.service";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {LastJobsTableComponent} from "./components/last-jobs-table/last-jobs-table.component";
import {FilesManagerModalComponent} from "./components/files-manager-modal/files-manager-modal.component";
import {LoadingPlaceholderComponent} from "./components/loading-placeholder/loading-placeholder.component";
import {AddEquipmentsModalComponent} from "./components/add-equipments-modal/add-equipments-modal.component";
import {ProfileInsuranceProofsComponent} from "./components/profile-modal/profile-insurance-proofs/profile-insurance-proofs.component";
import {NotificationsService} from "../providers/notifications.service";
import {SuggestionModalComponent} from "./components/suggestion-modal/suggestion-modal.component";
import {FileUploadButtonComponent} from "./components/file-upload-button/file-upload-button.component";
import {NotificationsCenterComponent} from "./components/notifications-center/notifications-center.component";
import {LocalizedDatePipe} from "../providers/localized-date.pipe";
import {FreightTypePipe} from "../providers/freight-type.pipe";
import {ParseToObjectsPipe} from "../providers/parse-to-objects.pipe";
import {DateSinceWhenPipe} from "../providers/date-since-when.pipe";
import {JobPriorityPipe} from "../providers/job-priority.pipe";
import {JobPriorityColorPipe} from "../providers/job-priority-color.pipe";
import {BidService} from "../providers/bid.service";

import {NgxMapLibreGLModule} from "@maplibre/ngx-maplibre-gl";
import {
  ProfileEmailVerifyComponent
} from "./components/profile-modal/profile-email-verify/profile-email-verify.component";
import {AngularSignaturePadModule} from "@almothafar/angular-signature-pad";
import {SignatureModalComponent} from "./components/signature-modal/signature-modal.component";
import {BolButtonComponent} from "./components/bol-button/bol-button.component";
import {
  NotificationsSettingsModalComponent
} from "./components/notifications-settings-modal/notifications-settings-modal.component";
import {
  UserManagementEditModalComponent
} from "./components/user-management-edit-modal/user-management-edit-modal.component";
import {
  NotificationSettingsListComponent
} from "./components/notification-settings-list/notification-settings-list.component";
import {BidPricingPipe} from "../providers/bid-pricing.pipe";
import {JobsService} from "../providers/jobs-service.service";
import {AutocompleteInputComponent} from "./components/autocomplete-input/autocomplete-input.component";
import {JobLogModalComponent} from "./components/job-log-modal/job-log-modal.component";
import {ChangePasswordModalComponent} from "./components/change-password-modal/change-password-modal.component";
import {TourIonPopoverModule} from "ngx-ui-tour-ionic";

const components = [
  ChangePasswordModalComponent,
  JobLogModalComponent,
  AutocompleteInputComponent,
  NotificationSettingsListComponent,
  UserManagementEditModalComponent,
  NotificationsSettingsModalComponent,
  BolButtonComponent,
  SignatureModalComponent,
  ProfileEmailVerifyComponent,
  JobPriorityColorPipe,
  JobPriorityPipe,
  DateSinceWhenPipe,
  BidPricingPipe,
  ParseToObjectsPipe,
  FreightTypePipe,
  LocalizedDatePipe,
  NotificationsCenterComponent,
  FileUploadButtonComponent,
  SuggestionModalComponent,
  AddEquipmentsModalComponent,
  LoadingPlaceholderComponent,
  FilesManagerModalComponent,
  LastJobsTableComponent,
  ProfileEmailChangeComponent,
  ProfileModalHomeComponent,
  ProfileModalComponent,
  ProfileInsuranceProofsComponent,
  LangSwitcherComponent,
  LogoutButtonComponent
];

const providers = [
  JobsService,
  NavParams,
  BidService,
  NotificationsService,
  ModalService,
  ToasterService,
  UserService,
]

const modules = [
  TourIonPopoverModule,
  AngularSignaturePadModule,
  NgxDatatableModule,
  SwiperModule,
  FlatpickrModule,
  RouterModule,
  TranslateModule,
  IonicModule,
  ReactiveFormsModule,
  FormsModule,
  CommonModule,
  NgxMapLibreGLModule
];

@NgModule({
  declarations: [...components],
  imports: [...modules],
  providers: [...providers],
  exports: [...modules, ...components]
})
export class SharedModule { }
