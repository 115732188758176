import { Component, OnInit } from '@angular/core';
import {IonNav, ModalController} from "@ionic/angular";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {FilesManagerModalComponent} from "../../files-manager-modal/files-manager-modal.component";
import * as Parse from 'parse';
import {InsuranceProofs} from "../../../../Entities/InsuranceProofs";
import {LoadingService} from "../../../../providers/loading.service";
import {ToasterService} from "../../../../providers/toaster.service";
import {NotificationsService} from "../../../../providers/notifications.service";

@Component({
  selector: 'app-profile-insurance-proofs',
  templateUrl: './profile-insurance-proofs.component.html',
  styleUrls: ['./profile-insurance-proofs.component.scss'],
})
export class ProfileInsuranceProofsComponent implements OnInit {

  insuranceProofForm: UntypedFormGroup;
  insuranceProof: Parse.Object;

  constructor(private notif: NotificationsService, private nav: IonNav, private fb: UntypedFormBuilder, private modalController: ModalController, private loading: LoadingService, private toaster: ToasterService) { }

  async ngOnInit() {
    this.insuranceProofForm = this.fb.group({
      insuranceProof: ['', Validators.required],
      cargoInsuranceAmount: ['', Validators.required],
      insuranceCompanyName: ['', Validators.required],
      expirationDate: ['', Validators.required],
    });
    this.insuranceProof = await new InsuranceProofs().query.first();
    if (this.insuranceProof) {
      const expirationDate = this.insuranceProof.get('expirationDate') as Date;
      console.log(expirationDate.toLocaleDateString('en-CA'));
      this.insuranceProofForm.patchValue({...this.insuranceProof.attributes, expirationDate: this.insuranceProof.get('expirationDate').toLocaleDateString('en-CA')});
    }
  }

  async goBack() {
    await this.nav.popToRoot();
  }

  uploadInsuranceProof(e) {
    this.insuranceProofForm.get('insuranceProof').setValue(e);
  }

  async openFilesManagerModal() {
    const modal = await this.modalController.create({
      component: FilesManagerModalComponent, id: '123', componentProps: {mode: 'select'}
    });
    await modal.present();
    const {data} = await modal.onDidDismiss();
    if (data) {
    }
  }

  async submitInsuranceProof() {
    if (this.insuranceProofForm.valid) {
      try {
        this.insuranceProofForm.get('expirationDate').setValue(new Date(this.insuranceProofForm.get('expirationDate').value));
        await this.loading.presentLoading();
        if (this.insuranceProof) {
          await this.insuranceProof.save(this.insuranceProofForm.value);
        } else {
          await new Parse.Object('InsuranceProofs').save(this.insuranceProofForm.value);
        }
        await this.toaster.showSaveSuccessToast();
        await this.notif.getStaticNotifications();
        await this.loading.removeLoading();
      } catch (e) {
        await this.toaster.showUnexpectedErrorToast(e.message);
        await this.loading.removeLoading();
      }
    }
  }
}
