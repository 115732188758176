import {ErrorHandler, Injectable, NgZone} from "@angular/core";
import {CurrentUserService} from "../providers/current-user.service";
import {Router} from "@angular/router";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
     private router: Router,
     private zone: NgZone
  ) {
  }

  async handleError(error: any) {
    console.log('Global handler Trigerred', error);
    switch (true) {
      case error.message.includes('session token'):
        localStorage.clear();
        this.zone.runOutsideAngular(() => {
          window.location.href = '/login';
        })

        // window.location.reload();
        break;

      default:
        break;
    }
  }
}
