import { Component, OnInit } from '@angular/core';
import {ToasterService} from "../../../providers/toaster.service";
import {TranslateService} from "@ngx-translate/core";
import {AlertController, IonNav, ModalController, NavParams} from "@ionic/angular";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CurrentUserService} from "../../../providers/current-user.service";
import * as Parse from 'parse';
import {Router} from "@angular/router";

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss'],
})
export class ChangePasswordModalComponent  implements OnInit {
  changePasswordForm: FormGroup;

  constructor(private toaster: ToasterService,
              private trans: TranslateService,
              private alertCtrl: AlertController,
              private modalController: ModalController,
              private nav: NavParams,
              private fb: FormBuilder,
              public cus: CurrentUserService,
              private router: Router
              ) {
    this.changePasswordForm = this.fb.group({
      password: ['', Validators.required],
      passwordCheck: ['', Validators.required],
    })
  }

  ngOnInit() {
    console.log(this.cus.currentUser.value);
  }

  closeModal() {
    this.modalController.dismiss();
  }

  async changePassword() {
    try {
      await Parse.Cloud.run('changePasswordModal', this.changePasswordForm.value);
      await this.toaster.showSaveSuccessToast();
      this.modalController.dismiss();
      this.cus.currentUser.next(undefined);
      localStorage.clear();
      await this.router.navigateByUrl('/login');
      window.location.reload();
    } catch (e) {
      console.log(e);
      this.toaster.showErrorToast(e.message);
    }
  }

  async goBack() {
  }
}
