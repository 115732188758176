import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
  Input,
  ElementRef, SimpleChanges, OnChanges, OnDestroy,
} from '@angular/core';
import maplibregl from 'maplibre-gl'; // or "const maplibregl = require('maplibre-gl');"
import {Map, NavigationControl, CenterZoomBearing} from 'maplibre-gl';
import * as Parse from "parse";
import {Jobs} from "./Jobs";
import {ControlComponent} from "@maplibre/ngx-maplibre-gl/lib/control/control.component";
import {
  GeoJSONSourceComponent,
  LayerComponent,
  MapService,
  MarkerComponent,
  PopupComponent
} from "@maplibre/ngx-maplibre-gl";
import {Router} from "@angular/router";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {CoordsUrlParam} from "./CoordsUrlParam";
import {ParseObject} from "../../Entities/Parse.object";
import {GeoJSON, GeoJsonObject, Geometry, Position} from "geojson";
import mapboxgl, {BoxZoomHandler, GeoJSONSource, MapLayerEventType, MapLayerMouseEvent, Marker} from "mapbox-gl";
import MapboxCircle from 'mapbox-gl-circle'
import {assignWith, isEqual} from "lodash";
import * as turf from "@turf/turf"
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {NavigateToMapService} from "../../providers/navigate-to-map.service";
import {ToasterService} from "../../providers/toaster.service";
import {RequestDetailsComponent} from "../request-details/request-details.component";
import {ModalController} from "@ionic/angular";
import {JobsService} from "../../providers/jobs-service.service";

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})


export class MapComponent implements OnInit, OnDestroy {

  // private testUrl = "http://144.126.150.174:5000/route/v1/driving/"
  //-71.06117630987475,48.40879016916293;-71.05002508839274,48.41211354614088?overview=full&geometries=geojson&steps=false"
//#region global variables
  public allJobs: Parse.Object[] = [];
  public lat = 48;
  public long = -70;
  public pathRequested: boolean = false;
  public popupAnchor: string = 'left';
  public geometry: any;

  protected marker = new maplibregl.Marker();
  public coordsTest: [number, number] = [0, 0];
  public debug: boolean = false;

  private circle: any;
  earthquakes: object;
  selectedPoint: GeoJSON.Feature | null;

  selectedCluster: { geometry: GeoJSON.Point; properties: any };
  public isVisible: boolean = false;

  //pour faire la difference de si on zoom sur un point ou la location de lutilisateur
  protected isFromRequestPage = false;
  public priority: number;


  public isMobile: boolean = false;
  public mapZoomOnLoad: number = 5;
  public plzWait: boolean = false;


  @ViewChild('myMap', {static: false}) myMap!: MapService;
  @ViewChild('myBtn', {static: false}) myBtn!: ControlComponent<any>;
  @ViewChild('pop', {static: false}) pop!: PopupComponent;
  @ViewChild('loadingMarker', {static: false}) loadingMarker!: MarkerComponent;
  @ViewChild('myClusterMarker', {static: false}) myClusterMarker!: GeoJSONSourceComponent;

  @ViewChild('myLine', {static: false}) myLine!: any;


//endregion


  constructor(private jobsService: JobsService, private modalCtrl: ModalController, private router: Router, private http: HttpClient, private navToService: NavigateToMapService, private toaster: ToasterService) {


    //@ts-ignore
    navigator.geolocation.clearWatch(1);


  }


  ngOnDestroy() {

    this.navToService.destroyVals();

  }

  async ngOnInit() {

    try {

      if (this.navToService.isDataSend) {
        this.navToService.goToMapCoord().subscribe((val) => {

          this.long = val[0];
          this.lat = val[1];
          this.isFromRequestPage = true
          this.mapZoomOnLoad = 10;


        })

      }


      navigator.geolocation.clearWatch(1);
      await this.getAllJobs();
      await this.getFeatureListe();


      // this.myMap.mapInstance.on('idle', () => {
      //   console.log(this.myMap.mapInstance.getZoom());
      //   console.log(this.clusterComponent);
      // })
    } catch (error) {
      console.log(error);
      this.toaster.showUnexpectedErrorToast(error.message);
    }

  }

  //Todo Possiblment refactorer le nom de cette function
  //Fonction caller sur l'evenement load de l'élément ngx maplibre map, initiallement cette function servais plus
  //A régler le Geolocator sur le load, mais la je la resize et pt plus
  async GeolocateOnLoad(event) {

    //console.log("MGL MAP ON LOAD");
    this.myMap.mapInstance.resize();
    const foo = this.myBtn.control._updateCamera

    this.myBtn.control.options.fitBoundsOptions.maxZoom = 5;
    this.myBtn.control.trigger();

    if (this.isFromRequestPage) {
      this.myBtn.control._updateCamera = () => {
      };

    }


    //géré les comportement on load de la map ( et non des composent angular) on load pour le mobile
    if (window.innerWidth < 1120) {
      this.isMobile = true;
      if (this.myMap.mapInstance.getCanvas().width <= 400 && this.myMap.mapInstance.getCanvas().width <= 600) {
        this.fixMapSize();
      }
      this.popupAnchor = 'center';
    }

  }


  moveMarker() {
    this.coordsTest[0] = this.coordsTest[0] + 0.3;
    this.coordsTest[1] = this.coordsTest[1] + 0.3;
  }

  updateMarker() {
    this.isVisible = true;
    this.coordsTest[0] = this.selectedPoint.properties.loadingAddressLng;
    this.coordsTest[1] = this.selectedPoint.properties.loadingAddressLat;
  }


//Remplire la feature liste de GeoFeatures  pour les layers des clusters
  async getFeatureListe() {
    var collection = [];
    this.allJobs.forEach(val => {
      collection.push(turf.point([val.get('loadingAddressLng'), val.get('loadingAddressLat')], {
        id: val.id,
        description: val.attributes.description,
        priority: val.attributes.priority,
        totalWeight: val.attributes.totalWeight,
        weightUnit: val.attributes.weightUnit,
        dimension: val.attributes.dimension,
        loadingAddressLng: val.attributes.loadingAddressLng,
        loadingAddressLat: val.attributes.loadingAddressLat,
        unloadingAddressLng: val.attributes.unloadingAddressLng,
        unloadingAddressLat: val.attributes.unloadingAddressLat,
        icon: val.attributes.priority === 1 ? 'assets/pngwing.png' : 'assets/greenMarker.png',


      }));

    });


    this.earthquakes = turf.featureCollection([])
    collection.forEach(val => {
      this.earthquakes['features'].push(val);
    })

  }


  //ca venais avec l'example de ngx maplibre pour des cluster html, cette partie la servirais a la pagination
  //je le laisse trainer pour le moment mais si je ne me sert pas, je vais éventuellement l'éffacer
  selectCluster(event: MouseEvent, feature: any) {
    event.stopPropagation(); // This is needed, otherwise the popup will close immediately
    // Change the ref, to trigger mgl-popup onChanges (when the user click on the same cluster)
    this.selectedCluster = {
      geometry: feature.geometry,
      properties: feature.properties,
    };
  }


  async onClick(evt: any, ree: any) {
    this.selectedPoint = evt;
    this.myMap.mapInstance.flyTo({center: [this.selectedPoint.properties.loadingAddressLng, this.selectedPoint.properties.loadingAddressLat]})

  }

  async getAllJobs() {
    try {
      await this.jobsService.getAllJobs({segment: 'all'});
      this.allJobs = this.jobsService.currentViewJobs;

      // this.myMap.mapInstance.on('idle', ()=>{
      //   //console.log(this.myClusterMarker);
      //   console.log(this.myMap.mapInstance.getZoom());
      // })

    } catch (error) {

      console.log(error);
      this.toaster.showUnexpectedErrorToast(error.message);


    }


  }


  async goToDetails(id) {
    try {
      const modal = await this.modalCtrl.create({
        component: RequestDetailsComponent,
        componentProps: {request: await new Parse.Query('Jobs').get(id)},
        cssClass: 'jobDetailsModal'
      });
      modal.present();
    } catch (e) {
      console.log(e);
      await this.toaster.showUnexpectedErrorToast(e.message);
    }
    // this.router.navigate(['request', id]);
  }


  async closePop(pop: PopupComponent) {
    pop.popupInstance.remove();
    this.selectedPoint = null;
    // console.log(pop.popupInstance.isOpen());
  }

  //ToDo Remettre la fonction avec des attirbues de parse obj
  //trouver comment repêcher mon mon array selon l'id passer dans mon alljobs

  //Cette fonction sert a faire une query au serveur osrm et a retourner la collection de d'élément géométric pour construire le traject sur la map
  async makeItinerary(jobCoords: any) {

    try {
      //this.updateMarker();


      this.priority = jobCoords.properties.priority


      let zoomLVL: number = 0;

      this.pathRequested = false;

      const url: CoordsUrlParam = new CoordsUrlParam();
      url.ipPath = "https://map.webeasy.ca/route/v1/driving/";
      url.coordLoad = jobCoords.properties.loadingAddressLng.toString() + "," + jobCoords.properties.loadingAddressLat.toString() + ";";    /* "-71.096039,48.403898" + ";"; */
      url.coordUnload = jobCoords.properties.unloadingAddressLng.toString() + "," + jobCoords.properties.unloadingAddressLat.toString();
      url.endUrlParam = "?overview=full&geometries=geojson&steps=false";


      const req = await this.http.get<JSON>(url.buildPath(url)).toPromise();


      this.geometry = req["routes"][0].geometry;
      let midPointIndex = Number(this.geometry.coordinates.length) / 2


      // var popup = new maplibregl.Popup({offset: 25}).setText(
      //   jobCoords.attributes.unloadingAddressLng.toString() + "\n" + jobCoords.attributes.unloadingAddressLat.toString()
      // );

      //set unloading marker, also reset it to new coords if an other itinairy is requested
      this.marker.setLngLat([jobCoords.properties.unloadingAddressLng, jobCoords.properties.unloadingAddressLat]);
      // this.marker.setPopup(popup);
      this.marker.addTo(this.myMap.mapInstance);


      // pour évaluer a quel distance le zoom doit allez relativement a la distances
      switch (true) {
        case (this.geometry.coordinates.length < 150) : {
          zoomLVL = 13;
          break;
        }
        case (this.geometry.coordinates.length < 300) : {
          zoomLVL = 11;
          break;
        }
        case (this.geometry.coordinates.length < 2000) : {
          zoomLVL = 7;
          break;
        }
        case (this.geometry.coordinates.length < 5000) : {
          zoomLVL = 6;
          break;
        }
        case (this.geometry.coordinates.length < 8000) : {
          zoomLVL = 5;
          break;
        }
        case (this.geometry.coordinates.length < 10000) : {
          zoomLVL = 4;
          break;
        }
        default: {
          zoomLVL = 3;
          break;
        }
      }


      // @ts-ignore
      this.myMap.mapInstance.flyTo({
        center: this.geometry.coordinates[Math.ceil(midPointIndex)],
        zoom: zoomLVL,
        speed: 2.2,

      });

      //show GeoJson line
      this.pathRequested = true;


      //  await this.searchJobsItiniary(this.geometry.coordinates, jobCoords);


    } catch (e) {

      console.log(e);

    }


  }

  /* Fonction qui set a lire toutes les données dans la liste des coordonée et a les comparer au placement des object de notre liste parse object
     pour detect si la coordonée actuelle est bien dans un rayon en metres approximatif que nous lui assignion.
    --On Doit lui passée une liste de coordones qui a été Query sur le back end, Je utlise aussi les coordoner du loadding address selectioner dans l'agorithme */
  async searchJobsItiniary(coordsList: [], currentJob: Parse.Object<Parse.Attributes>) {

    try {

      let i = 0;
      let jobsFound: [] = [];

      coordsList.forEach(val => {
        let lng: number = val[0];
        let lat: number = val[1];

        if (i % 5 == 0) {

          //    var debugIndex = 0;

          const longLat: [number, number] = [Number(lng.toFixed(7)), Number(lat.toFixed(7))];
          const allJobsInRadius = this.allJobs.map(job => {

            const jobCoordinate: [number, number] = [job.get('loadingAddressLng'), job.get('loadingAddressLat')];
            const distanceBetween = this.calcDistance(jobCoordinate, longLat);

            if (distanceBetween <= 15800) {

              if (isEqual(currentJob, job) == false) {
                let isNotUnique: boolean[] = [];

                if (jobsFound.length == 0) {
                  //@ts-ignore
                  jobsFound.push(jobCoordinate)
                  // this.drawSearchRadius(Number(jobCoordinate[0].toFixed(7)), Number(jobCoordinate[1].toFixed(7)), '#fd5b23');
                } else {

                  jobsFound.forEach(val => {
                    if (val[0] == jobCoordinate[0] && val[1] == jobCoordinate[1]) {
                      isNotUnique.push(true)
                    } else {
                      isNotUnique.push(false)
                    }
                  })

                  for (let bi = 0; bi < isNotUnique.length; bi++) {
                    if (isNotUnique[bi]) {
                      break;
                    } else if (bi == isNotUnique.length - 1 && isNotUnique[bi] == false) {
                      // @ts-ignore
                      jobsFound.push(jobCoordinate)
                      // this.drawSearchRadius(Number(jobCoordinate[0].toFixed(7)), Number(jobCoordinate[1].toFixed(7)), '#fd5b23');
                    }
                  }

                }

                return {job: job, distance: distanceBetween};
              }
            }
          })
        }
        i++;
      });


    } catch (e) {
      console.log(e);
    }


  }


//Déssine un cercle dans un rayon assigner, pour le moment ca sert juste a débuger et donnée une idée
  async drawSearchRadius(lng: number, lat: number, color?: string) {

    this.circle = new MapboxCircle({
      lat: Number(lat.toFixed(7)),
      lng: Number(lng.toFixed(7))
    }, 14000, {
      editable: false,
      fillColor: color || '#29AB87' /*'#29AB87'*/
    }).addTo(this.myMap.mapInstance)
      .setCenter({lat: Number(lat.toFixed(7)), lng: Number(lng.toFixed(7))});

  }


//creates a marker and draw it around, it creates a raduis and check distance of parse obj

  logMarketDrag($event: Marker) {
    const markerLongLat: [number, number] = [Number($event.getLngLat().lng.toFixed(7)), Number($event.getLngLat().lat.toFixed(7))];
    const allJobsInRadius = this.allJobs.map(job => {
      const jobCoordinate: [number, number] = [job.get('loadingAddressLng'), job.get('loadingAddressLat')];
      const distanceBetween = this.calcDistance(jobCoordinate, markerLongLat);
      if (distanceBetween <= 15000) {
        // console.log(job);
        return {job: job, distance: distanceBetween};
      }
    })
  }

  calcDistance([lon1, lat1], [lon2, lat2]) {
    const R = 6371e3; // metres
    const q1 = lat1 * Math.PI / 180; // φ, λ in radians
    const q2 = lat2 * Math.PI / 180;
    const q3 = (lat2 - lat1) * Math.PI / 180;
    const q4 = (lon2 - lon1) * Math.PI / 180;

    const a = Math.sin(q3 / 2) * Math.sin(q3) +
      Math.cos(q1) * Math.cos(q2) *
      Math.sin(q4 / 2) * Math.sin(q4 / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // in metres
    return d;
  }


  fixMapSize() {
    const refreshId = setInterval(() => {

      // console.log(this.myMap.mapInstance.getCanvas().width)
      if (this.myMap.mapInstance.getCanvas().width > 400) {
        this.myMap.mapInstance.resize();
        var isResolved = true;
      }
      if (isResolved) {
        clearInterval(refreshId);
      }

    }, 300)
  }

//Sur un click, Elle va allez ce centrer sur le point actuelle et le niveau qu'elle va zoomer, dépend du niveau de zoom actulle
  centerCluster(feature: any) {

    let currentZoom = this.myMap.mapInstance.getZoom();


    if (window.innerWidth < 1200) {

      this.myMap.mapInstance.flyTo({
        center: feature._geometry.coordinates,
        zoom: currentZoom >= 5 ? currentZoom + 1.8 : currentZoom + 3.8,
        speed: 2
      });
    } else {
      this.myMap.mapInstance.flyTo({
        center: feature._geometry.coordinates,
        zoom: currentZoom >= 6 ? currentZoom + 2.5 : currentZoom + 4,
        speed: 2
      });
    }


//    console.log(this.myMap.mapInstance.getZoom());

  }
}





