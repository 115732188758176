import {ParseObject} from "./Parse.object";

export class NotificationSubscriptions extends ParseObject {

  constructor() {
    super('NotificationSubscriptions');
  }


}
