import {Component, OnInit, ViewChild} from '@angular/core';
import {RouteDataService} from "../providers/route-data.service";
import {ModalService} from "../providers/modal.service";
import {TranslateService} from "@ngx-translate/core";
import {CurrentUserService} from "../providers/current-user.service";
import {ToasterService} from "../providers/toaster.service";
import {TourService} from "ngx-ui-tour-ionic";
import {IonMenu, IonSplitPane} from "@ionic/angular";



@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {

  @ViewChild('splitPane') splitpane: IonSplitPane;
  @ViewChild('menu') menu: IonMenu;
  public appPages;

  constructor(
    private toaster: ToasterService,
    public routeData: RouteDataService,
    public modal: ModalService, private trans: TranslateService, public currentUser: CurrentUserService, private tourService: TourService) {
  }

  async ngOnInit() {
    try {
     // console.log("main ngOnInit");
      await this.currentUser.getCurrentUser();
      if (this.currentUser.currentUser.value?.get('passwordChangeNeeded')) {
        this.modal.openChangePasswordModal();
      }
      await this.currentUser.fetchUserRoles();
      if (this.currentUser.isRole('Client') && this.currentUser.currentUser.value.get('showMenuTour') && !this.currentUser.currentUser.value.get('passwordChangeNeeded')) {
        this.tourService.initialize([
          {anchorId: 'requestsButton', content: this.trans.instant('tour.menu.requestsButtonContent'), title:  this.trans.instant('tour.menu.requestsButtonTitle')},
          {anchorId: 'profileButton', content: this.trans.instant('tour.menu.profileButtonContent'), title:  this.trans.instant('tour.menu.profileButtonTitle')},
          {anchorId: 'notificationsButton', content: this.trans.instant('tour.menu.notificationsButtonContent'), title:  this.trans.instant('tour.menu.notificationsButtonTitle')},
          {popoverClass: 'shiftUp',anchorId: 'suggestionsButton', content: this.trans.instant('tour.menu.suggestionsButtonContent'), title:  this.trans.instant('tour.menu.suggestionsButtonTitle')},
        ], {nextBtnTitle: this.trans.instant('next'), prevBtnTitle: this.trans.instant('back'),isOptional: true ,enableBackdrop: true, backdropConfig: {backgroundColor: '#000000c4'}, placement: {alignment: 'end'}})
        await this.menu.toggle();
        this.tourService.start();
        this.tourService.end$.subscribe(async e => {
          await this.currentUser.currentUser.value.save({showMenuTour: false});
        })
      }


    } catch(e) {
      console.log(e);
      await this.toaster.showUnexpectedErrorToast(e.message);
    }

  }

}
