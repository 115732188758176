import {Component, OnInit} from '@angular/core';
import {ToasterService} from "../../providers/toaster.service";
import {LoadingService} from "../../providers/loading.service";
import {FormBuilder, FormGroup, UntypedFormGroup} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import * as Parse from 'parse';
import {CurrentUserService} from "../../providers/current-user.service";
import {Location} from "@angular/common";
import {AlertController} from "@ionic/angular";

@Component({
  selector: 'app-new-request',
  templateUrl: './new-request.component.html',
  styleUrls: ['./new-request.component.scss'],
})
export class NewRequestComponent implements OnInit {

  modify: Boolean = false;
  jobToModify: Parse.Object;

  loadingDateDisplay: Date | string;
  unloadingDateDisplay: Date | string;

  // bounds = new google.maps.LatLng(48.3910229, -71.0274266)
  // acOptions = {
  //   location: this.bounds,
  //   radius: 150000,  // (in meters; this is 150Km)
  //   types: [],
  // };

  lastJob: Parse.Object;

  public newRequestForm: UntypedFormGroup;

  constructor(
    private alertCtrl: AlertController,
    private route: ActivatedRoute,
    private location: Location,
    private cus: CurrentUserService,
    private router: Router,
    private toaster: ToasterService,
    private loading: LoadingService,
    private fb: FormBuilder,
    public trans: TranslateService
  ) {
    this.newRequestForm = this.fb.group({
      jobNumber: [''],
      clientName: [''],
      clientPhone: [''],
      clientEmail: [''],
      freightType: [''],
      dimension: [''],
      totalWeight: [''],
      weightUnit: [''],
      description: [''],
      loadingDate: [''],
      unloadingDate: [''],
      loadingAvailableAt: [''],
      loadingDateFlexible: [false],
      unloadingAvailableAt: [''],
      unloadingDateFlexible: [false],
      loadingOpenHoursOnlyNotes: [''],
      unloadingOpenHoursOnlyNotes: [''],
      loadedHow: [''],
      unloadedHow: [''],
      unloadedBy: [''],
      loadedBy: [''],
      unloadingAddress: [''],
      loadingAddress: [''],
      moreInfos: [''],
      priority: [2],
      closedTrailerNeeded: [false],
      loadingVicinity: [''],
      unloadingVicinity: [''],
      approved: [true],
      loadingAddressLng: [],
      unloadingAddressLng: [],
      loadingAddressLat: [],
      unloadingAddressLat: [],
    })
  }

  async ngOnInit() {
    if (!this.cus.isRole('Admin')) {
      this.location.back();
    }
    this.route.params.subscribe(async p => {
      if (p.id) {
        this.modify = true;
        this.jobToModify = await new Parse.Query('Jobs').get(p.id);
        const valuesToPatch = {
          ...this.jobToModify.attributes,
          loadingDate: (this.jobToModify.get('loadingDate') as Date).toISOString(),
          unloadingDate: (this.jobToModify.get('unloadingDate') as Date).toISOString()
        };
        this.newRequestForm.patchValue(valuesToPatch);
      }
    })
    const lastJobQ = new Parse.Query('Jobs');
    lastJobQ.descending('createdAt');
    this.lastJob = await lastJobQ.first();
    console.log(this.lastJob);
  }

  async sendRequest() {
    if (this.modify) {
      const alert = await this.alertCtrl.create({
        header: this.trans.instant('resendNotifications'),
        subHeader: this.trans.instant('resendNotificationsText'),
        cssClass: 'delete-alert',
        buttons: [
          {
            text: this.trans.instant('no'),
            role: 'cancel',
            cssClass: 'color-danger',
            handler: async () => {
              await this.saveJob();
            }
          },
          {
            text: this.trans.instant('yes'),
            role: 'confirm',
            handler: async () => {
              await this.saveJob(true)
            }
          }
        ]
      })
      await alert.present();
    } else {
      await this.saveJob();
    }
  }

  async saveJob(notifyCarriers = false) {
    let job = new Parse.Object('Jobs');
    let modifyContext = false;
    if (this.jobToModify) {
      job = this.jobToModify;
      modifyContext = true;
    }
    if (!job.get('jobNumber')) {
      this.newRequestForm.controls['jobNumber'].setValue(this.generateJobNumber());
    }
    if (!job.get('approved')) {
      this.newRequestForm.controls['approved'].setValue(true);
    }
    const values = {
      ...this.newRequestForm.value,
      loadingDate: new Date(this.newRequestForm.controls['loadingDate'].value),
      unloadingDate: new Date(this.newRequestForm.controls['unloadingDate'].value),
      priority: Number(this.newRequestForm.controls['priority'].value)
    }
    await this.loading.presentLoading();
    try {
      await job.save(values, {context: {modifyJob: modifyContext, notifyCarriers: notifyCarriers, approveJob: true}});
      await this.router.navigate(['requests']);
      await this.loading.removeLoading();
    } catch (e) {
      await this.toaster.showUnexpectedErrorToast(e.message);
      await this.loading.removeLoading();
    }
  }



  // setLoadingDate(date: Date | string) {
  //   console.log(date);
  //   this.loadingDateDisplay = date;
  // }
  //
  // setUnloadingDate(date: Date | string) {
  //   console.log(date);
  //   this.unloadingDateDisplay = date;
  // }

  unloadingAddressSelect($event: google.maps.places.PlaceResult) {
    console.log($event);
    const postalCodes = $event.address_components.find(c => c.types.includes('postal_code'));
    this.newRequestForm.controls['unloadingAddressLng'].setValue($event.geometry.location.lng());
    this.newRequestForm.controls['unloadingAddressLat'].setValue($event.geometry.location.lat());
    this.newRequestForm.controls['unloadingVicinity'].setValue(`${$event.vicinity}, ${postalCodes?.short_name}`);
    this.newRequestForm.controls['unloadingAddress'].setValue($event.formatted_address);
  }

  loadingAddressSelect($event: google.maps.places.PlaceResult) {
    const postalCodes = $event.address_components.find(c => c.types.includes('postal_code'));
    this.newRequestForm.controls['loadingAddressLng'].setValue($event.geometry.location.lng());
    this.newRequestForm.controls['loadingAddressLat'].setValue($event.geometry.location.lat());
    this.newRequestForm.controls['loadingVicinity'].setValue(`${$event.vicinity}, ${postalCodes?.short_name}`);
    this.newRequestForm.controls['loadingAddress'].setValue($event.formatted_address);
  }

  generateJobNumber(): string {
    return Math.floor(100000 + Math.random() * 900000).toString();
  }
}
