import {Injectable, Output} from '@angular/core';
import {Observable} from "rxjs";
import {Router} from "@angular/router";
import EventEmitter from "events";

@Injectable({
  providedIn: 'root'
})
export class NavigateToMapService {
  public isDataSend: boolean= false;
 protected rowObj!: any;


  constructor(private router: Router) {

  }


  //Fetch and activate map coords
  fetchCoordsObj(row: any){
    if(row != undefined){
        this.isDataSend = true;
    }
    this.rowObj = row;

    setTimeout(async () => {
      await this.router.navigate(["map"]);
    })

  }

  //Relay map coords to navigate to
  goToMapCoord(): Observable<[number, number]> {
   // console.log(row['loadingAddressLng'])
    try {
      const coords: [number, number] = [this.rowObj['loadingAddressLng'], this.rowObj['loadingAddressLat']]
      return new Observable<[number, number]>((subscriber) => {
          subscriber.next(coords);
      })
    }catch (e){
      console.log(e)
    }
  }

  destroyVals(){
    this.rowObj = null;
    this.isDataSend = false;
  }


}
