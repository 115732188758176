import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Equipments} from "../../Entities/Equipments";
import {ModalService} from "../../providers/modal.service";
import {TranslatorService} from "../../providers/translator.service";
import {ModalController} from "@ionic/angular";
import {AddEquipmentsModalComponent} from "../../shared/components/add-equipments-modal/add-equipments-modal.component";
import {LoadingService} from "../../providers/loading.service";
import {ToasterService} from "../../providers/toaster.service";
import * as _ from 'lodash';
import {SwiperOptions} from "swiper";

@Component({
  selector: 'app-equipments',
  templateUrl: './equipments.component.html',
  styleUrls: ['./equipments.component.scss'],
})
export class EquipmentsComponent implements OnInit {


  public data: any;
  public columns: any;
  public rows: Parse.Object[];
  lodash = _;

  sortedEquipments: any;
  slideOpts: SwiperOptions = {
    slidesPerView: 1.6, allowTouchMove: true, autoHeight: false,
    breakpoints: {
      576: {
        slidesPerView: 1.6
      },
      768: {
        slidesPerView: 3.2
      },
      992: {
        slidesPerView: 4.5
      }
    }
  };


  constructor(private toaster: ToasterService, private loading: LoadingService, public modal: ModalService, public trans: TranslatorService, private modalCtrl: ModalController) {
  }

  async ngOnInit() {
    const eQuery = await new Equipments().query.include(['type', 'images']).subscribe();
    console.log(eQuery);
    eQuery.on('update', (e) => {
     // console.log(e);
    })
    this.updateEquipments(await new Equipments().query.include(['type', 'images']).findAll());



  }

  async modify(row: Parse.Object) {
    const modal = await this.modalCtrl.create({
      component: AddEquipmentsModalComponent,
      componentProps: {mode: 'modify', equipmentToModify: row}
    });
    await modal.present();
    const {data} = await modal.onDidDismiss();
    this.updateEquipments(await new Equipments().query.include(['type', 'images']).findAll());
  }

  async addEquipment() {
    const modal = await this.modalCtrl.create({
      component: AddEquipmentsModalComponent,
    });
    await modal.present();
    const {data} = await modal.onDidDismiss();
    this.updateEquipments(await new Equipments().query.include(['type', 'images']).findAll());
  }

  async remove(row: Parse.Object) {
    try {
      await this.loading.presentLoading();
      await row.destroy();
      this.updateEquipments(await new Equipments().query.include(['type', 'images']).findAll());
      await this.toaster.showSuccessToast('deleteSuccessMessage');
      await this.loading.removeLoading();
    } catch (e) {
      await this.toaster.showUnexpectedErrorToast(e.message);
      await this.loading.removeLoading();
    }
  }

  updateEquipments(equipments: any) {
    this.sortedEquipments = _.groupBy(equipments, (v: Parse.Object) => v.get('type').get(`category_${this.trans.currentLang}`));
  }
}
