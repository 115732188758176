import {Component, OnInit} from '@angular/core';
import {ActionSheetController, ModalController} from "@ionic/angular";
import {SignatureModalComponent} from "../signature-modal/signature-modal.component";
import {ToasterService} from "../../../providers/toaster.service";

@Component({
  selector: 'app-bol-button',
  templateUrl: './bol-button.component.html',
  styleUrls: ['./bol-button.component.scss'],
})
export class BolButtonComponent implements OnInit {

  constructor(public modalCtrl: ModalController, public toaster: ToasterService, private actionSheetCtrl: ActionSheetController) {
  }

  ngOnInit() {
  }


  async openActionSheet() {
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Bill of lading',
      buttons: [
        {
          text: 'Signatures',
          handler: async () => {
            try {
              const modal = await this.modalCtrl.create({
                component: SignatureModalComponent,
                cssClass: 'jobDetailsModal'
              });
              return await modal.present();
            } catch (e) {
              console.log(e);
              await this.toaster.showUnexpectedErrorToast(e.message);
            }
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel',
          },
        },
      ],
    });
    actionSheet.present();

  }
}
