import {ChangeDetectorRef, Injectable} from '@angular/core';
import * as Parse from "parse";
import {AlertController} from "@ionic/angular";
import {ToasterService} from "./toaster.service";
import {TranslateService} from "@ngx-translate/core";
import {CurrentUserService} from "./current-user.service";
import {JobsService} from "./jobs-service.service";

@Injectable({
  providedIn: 'root'
})
export class BidService {

  constructor(
    private jobService: JobsService,
    private alertCtrl: AlertController,
    private toaster: ToasterService,
    private trans: TranslateService,
    private cus: CurrentUserService,
  ) { }

  public async bid(job: Parse.Object): Promise<void> {
    const userBid = await job.relation('bids').query().equalTo('owner', this.cus.currentUser.value).first();
    return new Promise(async (resolve) => {
      const extraAlertButtons = [];
      if (userBid) {
        extraAlertButtons.push({
          text: this.trans.instant('cancelBid'),
          cssClass: 'color-danger',
          handler: async () => {
            try {
              await userBid.destroy();
              this.jobService.yourOffersCount -= 1;
              await this.toaster.showSuccessToast('bidCanceledSuccessfuly')
              resolve()
            } catch (e) {
              console.log(e);
              await this.toaster.showErrorToast(e.code);
            }
          }
        })
      }
      const alert = await this.alertCtrl.create({
        cssClass: 'bidAlert',
        header: this.trans.instant('enterYourBid'),
        subHeader: userBid ? `${this.trans.instant('yourOffer')} : ${userBid.get('amount')}` : '',
        buttons: [
          ...extraAlertButtons,
          {
            text: userBid ? this.trans.instant('modify') : this.trans.instant('placeBid'),
            handler: async ({amount, note}) => {
              try {
                if (!Number(amount) || Number(amount) < 1) {
                  throw new Error('bidCantBe0');
                }
                await Parse.Cloud.run('placeBid', {job: job.toPointer(), amount: Number(amount), note: note});
                this.jobService.yourOffersCount += 1;
                await this.toaster.showSuccessToast('bidSuccessful')
                resolve()
              } catch (e) {
                console.log(e);
                await this.toaster.showErrorToast(e.code ? e.code : e.message);
              }
            }
          },
        ],
        inputs: [
          {
            name: 'amount',
            type: 'number',
            placeholder: 'Inscrivez votre offre, ex: 250',
            value: userBid ? userBid.get('amount') : null
          },
          {
            placeholder: 'Inscrire une note (Optionel)',
            name: 'note',
            type: 'textarea',
            value: userBid ? userBid.get('note'): null
          }
        ],
      });

      await alert.present();
    })

  }

}
