// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userFormContainer {
  width: 50%;
  margin: 0 auto;
}

.profileFormContainer {
  width: 50%;
  margin: 0 auto;
}

.formsContainer {
  display: flex;
  flex-direction: row;
}

.notificationContainer {
  width: 50%;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/user-management-edit-modal/user-management-edit-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,cAAA;AACF;;AAEA;EACE,UAAA;EACA,cAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,UAAA;AACF","sourcesContent":[".userFormContainer {\r\n  width: 50%;\r\n  margin: 0 auto;\r\n}\r\n\r\n.profileFormContainer {\r\n  width: 50%;\r\n  margin: 0 auto;\r\n}\r\n\r\n.formsContainer {\r\n  display: flex;\r\n  flex-direction: row;\r\n}\r\n\r\n.notificationContainer {\r\n  width: 50%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
