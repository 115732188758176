import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, ActivationEnd, Router} from "@angular/router";
import {filter, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class RouteDataService {

  public title: string;

  constructor(private router: Router) {
    this.router.events.pipe(
      filter(event => event instanceof ActivationEnd),
      map(route => route['snapshot'])
    ).subscribe((data: ActivatedRouteSnapshot) => {
      // console.log(data);
      // this.title = data.routeConfig.data?.title;
    })

  }

}
