import {ChangeDetectorRef, EventEmitter, Injectable} from '@angular/core';
import * as Parse from 'parse';
import {ToasterService} from "./toaster.service";
import {CurrentUserService} from "./current-user.service";
import * as _ from 'lodash';

interface IJobRequestOptions {
  segment: string;
  searchValue?: string;
  pageOffset?: number;
  pageSize?: number;
}

@Injectable({
  providedIn: 'root'
})

export class JobsService {

  public changeDetectionEmitter: EventEmitter<void> = new EventEmitter<void>();


  public currentViewJobs: Parse.Object[];
  public currentViewJobsCount: number;

  public requestsComponentViewJobs: { [p: string]: any; id: string }[];


  // Used in all toolbars
  public allJobsCount: number;

  // Used in toolbar for crriers
  public assignedCount: number;
  public yourOffersCount: number;
  public dismissedCount: number;

  // User in toolbar for admins
  public completedCount: number;
  public deletedCount: number;
  public toBeApprovedCount: number;
  public winnersCount: number;

  // Used to constraint the job queries when needed;
  private _dismissedJobsIds: string[] = [];
  private _biddedJobsIds: string[] = [];
  private _filters: string[] = [];
  public statusFilter: string = 'all';

  // Needs to be stored at class level so we can unsubscribe and re-subscribe when we make a new query, preventing opening multiple web sockets
  private _currentViewJobsSubscription: Parse.LiveQuerySubscription;
  private _currentViewJobsQuery: Parse.Query;

  // Subscriptions used for counts
  private _allJobsCountSubscription: Parse.LiveQuerySubscription;
  private _dismissedCountSubscription: Parse.LiveQuerySubscription;
  private _assignedCountSubscription: Parse.LiveQuerySubscription;
  private _yourOfferCountSubscription: Parse.LiveQuerySubscription;

  private _toBeAprovedCountSubscription: Parse.LiveQuerySubscription;
  private _winnersCountSubscription: Parse.LiveQuerySubscription;
  private _completedCountSubscription: Parse.LiveQuerySubscription;
  private _deletedCountSubscription: Parse.LiveQuerySubscription;



  constructor(private toaster: ToasterService, private cus: CurrentUserService) {
  }

  public async getAllJobs(options?: IJobRequestOptions) {
    try {
      this._currentViewJobsQuery = new Parse.Query('Jobs');
      if (this._currentViewJobsSubscription) {
        this._currentViewJobsSubscription.unsubscribe();
      }
      // Initisalising the queries. Below we make two queries, one for dismissed jobs, and one for filters so we can use these values
      // to filter the jobs in our main query. The reason for that is because we are using livequery with Subscription and it does not support
      // Multiple queries with pointers or relationships. We get around 50ms added to our request which is a small price to pay to be able to use LiveQueries.
      let jobsQuery = this._currentViewJobsQuery;

      // Query to get dismissed job for current user. We use those IDs to retrieve jobs NOT in that list
      this._dismissedJobsIds = await this._getDismissedJobs();

      this._biddedJobsIds = await this._getJobsWithBids();

      //Query filters for job type checkboxes, we use it to query jobs that have the filter checked
      this._filters = await this._getFilters();

      // If we have a value to search in each keysToSearch we create an array of constaint for each fields. Basicly what it does
      // is add "OR jobNumber contains searchValue", "OR loadingAddress contains searchValue" etc..
      if (options?.searchValue) {
        jobsQuery = await this._constraintBySearchValue(options.searchValue);
      }

      jobsQuery.addDescending([options?.segment === 'toBeApproved' ? 'createdAt' : 'approvedAt']);
      jobsQuery.containedIn('freightType', this._filters);
      if (this.statusFilter !== 'all') {
        jobsQuery.equalTo('status', this.statusFilter);
      }


      this._countEverything();
      // this._countEverything(new Parse.Query.fromJSON('Jobs', jobsQuery.toJSON()));

      // By default, our server never return completed, deleted or assigned jobs but we have to specify it anyway because ADMINS
      // Have access to all rows in the ACL

      switch (true) {
        case options.segment === 'all':
          jobsQuery = this._allJobsQueryParams(jobsQuery);
          break;

        case options.segment === 'contracts':
          jobsQuery = this._assignedJobsQueryParams(jobsQuery);
          break;

        case options.segment === 'offers':
          jobsQuery = this._offersJobsQueryParams(jobsQuery);
          break;

        case options.segment === 'dismissed':
          jobsQuery = this._dismissedJobsQueryParams(jobsQuery);
          break;

        // Admin segment, to show jobs to be approved
        case options.segment === 'toBeApproved':
          jobsQuery = this._toBeApprovedQueryParams(jobsQuery);
          break;

        // Admin segment, to show deleted jobs.
        case options.segment === 'deleted':
          jobsQuery = this._deletedJobsQueryParams(jobsQuery);
          break;

        // Admin segment, to show copleted jobs
        case options.segment === 'completed':
          jobsQuery = this._completedJobsQueryParams(jobsQuery);
          break;

        // Admin segment, to show assigned jobs
        case options.segment === 'winners':
          jobsQuery = this._winnersJobsQueryParams(jobsQuery);
          break;

        default:
          break;
      }

      // Placing the total job count for this query before the limit and skip to get the proper pagination when using search field or filters
      this.currentViewJobsCount = await jobsQuery.count();
      this._currentViewJobsSubscription = await jobsQuery.subscribe();


      jobsQuery.limit(options?.pageSize ? options?.pageSize : 150000);
      if (options?.pageOffset && options?.pageSize) {
        jobsQuery.skip(options?.pageOffset * options?.pageSize);
      }

      // Here we subscribe to the query to enable the real time update capabilities on the query
      // Optionally we can use the hooks below to do actions when something happen, by default, we don't
      // really have to besides on Enter and Leave
      this._currentViewJobsSubscription.on('update', async (o) => {
        // console.log('On UPDATE main query : ', o)
        // this.currentViewJobs = await jobsQuery.find();
        // this.currentViewJobsCount = await jobsQuery.count();
        this.changeDetectionEmitter.emit();
      })
      this._currentViewJobsSubscription.on('enter', async (o) => {
        // console.log('On ENTER main query : ', o)
        this.currentViewJobs = await jobsQuery.find();
        this.currentViewJobsCount = await jobsQuery.count();
        this._setRequestsComponentViewJobs();
      })
      this._currentViewJobsSubscription.on('leave', async (o) => {
        // console.log('On LEAVE main query : ', o)
        this.currentViewJobs = await jobsQuery.find();
        this.currentViewJobsCount = await jobsQuery.count();
        this._setRequestsComponentViewJobs();
      })
      // Fetch the results
      this.currentViewJobs = await jobsQuery.find();
      this._setRequestsComponentViewJobs();

    } catch (e) {
      await this.toaster.showUnexpectedErrorToast(e.message);
    }

  }

  private _setRequestsComponentViewJobs() {
    this.requestsComponentViewJobs = this.currentViewJobs.map(o => {
      return {id: o.id, ...o.attributes}
    });
  }

  private async _getDismissedJobs(): Promise<string[]> {
    const dismissedJobsQuery = new Parse.Query('DismissedJobs')
      .equalTo('dismissedBy', this.cus.currentUser.value)
      .limit(150000);
    return _.map(await dismissedJobsQuery.find(), (o) => o.get('job')?.id);
  }

  private async _getJobsWithBids(): Promise<string[]> {
    const allUserBidsQuery = new Parse.Query('Bids')
      .equalTo('owner', this.cus.currentUser.value)
      .limit(2500)
    return _.map(await allUserBidsQuery.find(), (o) => o.get('job')?.id);
  }

  private async _getDismissedJobsCount() {
    const jobsMatchQuery = new Parse.Query('Jobs')
      .equalTo('completed', false)
      .equalTo('approved', true)
      .doesNotExist('deletedAt')
    const dismissedJobsQuery = new Parse.Query('DismissedJobs')
      .equalTo('dismissedBy', this.cus.currentUser.value)
      .matchesQuery('job', jobsMatchQuery)
      .limit(150000);
    this.dismissedCount = await dismissedJobsQuery.count();
  }

  private async _getFilters() {
    const filterQuery = new Parse.Query('JobsFiltersSettings')
      .equalTo('owner', this.cus.currentUser.value)
      .include('jobType')
      .equalTo('checked', true)
    return _.map(await filterQuery.find(), (o) => {
      return o.get('jobType').get('name');
    });
  }

  private async _constraintBySearchValue(searchValue) {
    const keysToSearch = ['jobNumber', 'loadingAddress', 'description', 'unloadingAddress', 'loadingVicinity', 'unloadingVicinity', 'notes', 'clientPhone', 'clientName'];
    const constraintQueries: Parse.Query[] = [];
    keysToSearch.forEach(field => {
      const q = new Parse.Query('Jobs')
      q.matches(field, new RegExp(searchValue), 'i');
      constraintQueries.push(q);
    })
    return Parse.Query.or(...constraintQueries);
  }


  private _allJobsQueryParams(q: Parse.Query): Parse.Query {
    q.doesNotExist('deletedAt');
    q.notContainedIn('objectId', this._dismissedJobsIds);
    q.equalTo('completed', false);
    q.equalTo('approved', true);
    q.doesNotExist('winner');
    return q;
  }

  private _dismissedJobsQueryParams(q: Parse.Query): Parse.Query {
    q.doesNotExist('deletedAt');
    q.containedIn('objectId', this._dismissedJobsIds);
    q.equalTo('completed', false);
    q.equalTo('approved', true);
    q.doesNotExist('winner');
    return q;
  }

  private _offersJobsQueryParams(q: Parse.Query): Parse.Query {
    q.doesNotExist('deletedAt');
    q.notContainedIn('objectId', this._dismissedJobsIds);
    q.containedIn('objectId', this._biddedJobsIds);
    q.equalTo('completed', false);
    q.equalTo('approved', true);
    q.doesNotExist('winner');
    return q;
  }

  private _toBeApprovedQueryParams(q: Parse.Query): Parse.Query {
    q.doesNotExist('deletedAt');
    q.equalTo('completed', false);
    q.equalTo('approved', false);
    q.doesNotExist('winner');
    return q;
  }

  private _deletedJobsQueryParams(q: Parse.Query): Parse.Query {
    q.exists('deletedAt');
    return q;
  }

  private _completedJobsQueryParams(q: Parse.Query): Parse.Query {
    q.doesNotExist('deletedAt');
    q.equalTo('completed', true);
    return q;
  }

  private _winnersJobsQueryParams(q: Parse.Query): Parse.Query {
    q.doesNotExist('deletedAt');
    q.equalTo('completed', false);
    q.exists('winner');
    return q;
  }

  private _assignedJobsQueryParams(q: Parse.Query): Parse.Query {
    q.doesNotExist('deletedAt');
    q.equalTo('approved', true);
    q.equalTo('completed', false);
    q.equalTo('winner', this.cus.currentUser.value);
    return q;
  }

  private async _countEverything(): Promise<void> {
    this._countAllJobs();

    if (this.cus.isRole('Transporteur')) {
      this._countDismissed();
      this._countAssigned();
      this._countYourOffers();
    }

    if (this.cus.isRole('Admin') || this.cus.isRole('Client')) {
      this._countToBeApproved();
      this._countWinner();
      this._countCompleted();
      this._countDeleted();
    }
  }

  private async _countDismissed(): Promise<void> {
    let query = new Parse.Query('Jobs');
    // query.containedIn('freightType', this._filters)
    if (this._dismissedCountSubscription) {
      this._dismissedCountSubscription.unsubscribe();
    }
    query = this._dismissedJobsQueryParams(query);
    this._dismissedCountSubscription = await query.subscribe();
    this._dismissedCountSubscription.on('leave', () => {
      if (this.dismissedCount != 0) {
        this.dismissedCount -= 1;
      }
    })
    this._dismissedCountSubscription.on('create', () => {
      this.dismissedCount += 1;
    })
    this.dismissedCount = await query.count();
  }


  private async _countAssigned() {
    let query = new Parse.Query('Jobs');
    // query.containedIn('freightType', this._filters)
    if (this._assignedCountSubscription) {
      this._assignedCountSubscription.unsubscribe();
    }
    query = this._assignedJobsQueryParams(query);
    this._assignedCountSubscription = await query.subscribe();
    this._assignedCountSubscription.on('leave', async () => {
      this.assignedCount = await query.count();
    })
    this._assignedCountSubscription.on('enter', async () => {
      this.assignedCount = await query.count();
    })
    this.assignedCount = await query.count();
  }

  private async _countYourOffers() {
    let query = new Parse.Query('Jobs');
    // query.containedIn('freightType', this._filters)
    if (this._yourOfferCountSubscription) {
      this._yourOfferCountSubscription.unsubscribe();
    }
    query = this._offersJobsQueryParams(query);
    this._yourOfferCountSubscription = await query.subscribe();
    this._yourOfferCountSubscription.on('leave', async () => {
      this.yourOffersCount = await query.count();
    })
    this._yourOfferCountSubscription.on('enter', async () => {
      this.yourOffersCount = await query.count();
    })
    this.yourOffersCount = await query.count();
  }

  private async _countAllJobs() {
    let query = new Parse.Query('Jobs');
    // query.containedIn('freightType', this._filters)
    if (this._allJobsCountSubscription) {
      this._allJobsCountSubscription.unsubscribe();
    }
    query = this._allJobsQueryParams(query);
    this._allJobsCountSubscription = await query.subscribe();
    this._allJobsCountSubscription.on('leave', async () => {
      this.allJobsCount = await query.count();
    })
    this._allJobsCountSubscription.on('enter', async () => {
      this.allJobsCount = await query.count();
    })
    this.allJobsCount = await query.count();
  }

  private async _countToBeApproved() {
    let query = new Parse.Query('Jobs');
    // query.containedIn('freightType', this._filters)
    if (this._toBeAprovedCountSubscription) {
      this._toBeAprovedCountSubscription.unsubscribe();
    }
    query = this._toBeApprovedQueryParams(query);
    this._toBeAprovedCountSubscription = await query.subscribe();
    this._toBeAprovedCountSubscription.on('leave', async () => {
      this.toBeApprovedCount = await query.count();
    })
    this._toBeAprovedCountSubscription.on('enter', async () => {
      this.toBeApprovedCount = await query.count();
    })
    this.toBeApprovedCount = await query.count();
  }

  private async _countWinner() {
    let query = new Parse.Query('Jobs');
    // query.containedIn('freightType', this._filters)
    if (this._winnersCountSubscription) {
      this._winnersCountSubscription.unsubscribe();
    }
    query = this._winnersJobsQueryParams(query);
    this._winnersCountSubscription = await query.subscribe();
    this._winnersCountSubscription.on('leave', async () => {
      this.winnersCount = await query.count();
    })
    this._winnersCountSubscription.on('enter', async () => {
      this.winnersCount = await query.count();
    })
    this.winnersCount = await query.count();
  }

  private async _countCompleted() {
    let query = new Parse.Query('Jobs');
    // query.containedIn('freightType', this._filters)
    if (this._completedCountSubscription) {
      this._completedCountSubscription.unsubscribe();
    }
    query = this._completedJobsQueryParams(query);
    this._completedCountSubscription = await query.subscribe();
    this._completedCountSubscription.on('leave', async () => {
      this.completedCount = await query.count();
    })
    this._completedCountSubscription.on('enter', async () => {
      this.completedCount = await query.count();
    })
    this.completedCount = await query.count();
  }

  private async _countDeleted() {
    let query = new Parse.Query('Jobs');
    // query.containedIn('freightType', this._filters)
    if (this._deletedCountSubscription) {
      this._deletedCountSubscription.unsubscribe();
    }
    query = this._deletedJobsQueryParams(query);
    this._deletedCountSubscription = await query.subscribe();
    this._deletedCountSubscription.on('leave', async () => {
      this.deletedCount = await query.count();
    })
    this._deletedCountSubscription.on('enter', async () => {
      this.deletedCount = await query.count();
    })
    this.deletedCount = await query.count();
  }
}
