// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup-form-container {
  overflow: auto;
  width: 60%;
  margin: 0 auto;
}

.forgot-password-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

@media (max-width: 768px) {
  .signup-form-container {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/auth/signup/signup.component.scss","webpack://./src/mixins/responsive.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,UAAA;EACA,cAAA;AADF;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,2BAAA;AADF;;ACCI;EDMF;IACE,WAAA;EAHF;AACF","sourcesContent":["@import 'responsive';\r\n\r\n.signup-form-container {\r\n  overflow: auto;\r\n  width: 60%;\r\n  margin: 0 auto;\r\n}\r\n\r\n.forgot-password-container{\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: flex-start;\r\n}\r\n\r\n@include screen('md') {\r\n\r\n\r\n  .signup-form-container{\r\n    width: 100%;\r\n  }\r\n}\r\n","$breakpoints: (\r\n  'sm':  576px,\r\n  'md': 768px,\r\n  'lg':  992px,\r\n  'xl':  1200px\r\n) !default;\r\n\r\n@mixin screen($breakpoint) {\r\n  // If the key exists in the map\r\n  @if map-has-key($breakpoints, $breakpoint) {\r\n    // Prints a media query based on the value\r\n    @media (max-width: map-get($breakpoints, $breakpoint)) {\r\n      @content;\r\n    }\r\n  }\r\n\r\n    // If the key doesn't exist in the map\r\n  @else {\r\n    @warn \"Unfortunately, no value could be retrieved from `#{$breakpoint}`. \"\r\n        + \"Available breakpoints are: #{map-keys($breakpoints)}.\";\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
