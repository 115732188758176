import {Component, OnInit, ViewChild} from '@angular/core';
import * as Parse from "parse";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {IonInput, IonNav, ModalController} from "@ionic/angular";
import {LoadingService} from "../../../../providers/loading.service";
import {ToasterService} from "../../../../providers/toaster.service";
import {Profile} from "../../../../Entities/Profile";
import {ProfileEmailChangeComponent} from "../profile-email-change/profile-email-change.component";
import {CurrentUserService} from "../../../../providers/current-user.service";
import {ProfileInsuranceProofsComponent} from "../profile-insurance-proofs/profile-insurance-proofs.component";
import {ChangePasswordModalComponent} from "../../change-password-modal/change-password-modal.component";

@Component({
  selector: 'app-profile-modal-home',
  templateUrl: './profile-modal-home.component.html',
  styleUrls: ['./profile-modal-home.component.scss'],
})
export class ProfileModalHomeComponent implements OnInit {

  profile: Parse.Object;
  profileForm: UntypedFormGroup;
  formValueChanged = false;
  email: string;
  phoneNumber: string;

  @ViewChild('autocompleteField') autocompleteField: IonInput;

  constructor(public user: CurrentUserService, private nav: IonNav, private modalController: ModalController, private fb: UntypedFormBuilder, private loading: LoadingService, private toaster: ToasterService) {
    this.profileForm = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      address: ['', Validators.required],
      companyName: ['', ],
      tps: ['', ],
      tvq: ['', ],
    });
  }


  async ngOnInit() {
    try {
      this.user.currentUser.subscribe(async user => {
        this.profile = await new Profile().getUserProfile(user);
        this.email = user.get('email');
        this.phoneNumber = this.profile.get('phone_number');
        this.profileForm.patchValue(this.profile.attributes);
      })
      this.profileForm.valueChanges.subscribe(x => {
        this.formValueChanged = true;
      })
    } catch(e) {
      await this.toaster.showUnexpectedErrorToast(e.message);
    }
  }

  async saveProfile() {
    if (this.profileForm.valid) {
      this.loading.presentLoading();
      try {
        await this.profile.save(this.profileForm.value);
        await this.loading.removeLoading();
        await this.toaster.showSaveSuccessToast();
        this.formValueChanged = false;
      } catch (e) {
        await this.loading.removeLoading();
        await this.toaster.showSaveErrorToast();
      }
    }
  }

  setAddress($event: google.maps.places.PlaceResult) {
    this.profileForm.patchValue({address: $event?.formatted_address});
  }

  setBillingAddress($event: google.maps.places.PlaceResult) {
    this.profileForm.patchValue({billingAddress: $event?.formatted_address});
  }

  emailChange() {
    this.nav.push(ProfileEmailChangeComponent);
  }

  phoneChange() {

  }

  goToInsuranceProofs() {
    this.nav.push(ProfileInsuranceProofsComponent);
  }

  passwordChange() {
    this.nav.push(ChangePasswordModalComponent)
  }
}
