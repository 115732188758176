import { Pipe, PipeTransform } from '@angular/core';
import {CurrentUserService} from "./current-user.service";

@Pipe({
  name: 'bidPricing'
})
export class BidPricingPipe implements PipeTransform {

  constructor(private cus: CurrentUserService) {
  }
  transform(value: Parse.Object, ...args: unknown[]): unknown {
    const amount = value.get('amount');
    switch (true) {
      case (this.cus.isRole('Employee')):
        return this.adjustedValue(amount).toFixed(2);

      default:
        return amount;
    }
  }

  // return true if in range, otherwise false
  private inRange(x, min, max) {
    return ((x-min)*(x-max) <= 0);
  }

  private adjustedValue(value) {
    if (this.inRange(value,0,549)) return value * 1.3;
    if (this.inRange(value, 550, 999)) return value * 1.25;
    if (this.inRange(value, 1000, 1800)) return value * 1.2;
    if (this.inRange(value, 1801, 9999999)) return value * 1.15;
    return value;
  }

}
