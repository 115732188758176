import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jobPriority'
})
export class JobPriorityPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    switch (value) {
      case 1:
        return `Urgent`
      case 2:
        return 'Normal'
    }
  }

}
