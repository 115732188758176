import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from "@ionic/angular";
import * as Parse from 'parse';
import * as _ from 'lodash';

@Component({
  selector: 'app-job-log-modal',
  templateUrl: './job-log-modal.component.html',
  styleUrls: ['./job-log-modal.component.scss'],
})
export class JobLogModalComponent  implements OnInit {

  currentJob: Parse.Object;
  actions: Parse.Object[];
  actionsTypes: Parse.Object[];
  userProfiles: {[key: string] : Parse.Object} = {};

  constructor(private modalController: ModalController, public nav: NavParams) { }

  async ngOnInit() {
    this.currentJob = this.nav.get('job');
    this.actionsTypes = await new Parse.Query('ActionsTypes').findAll();
    this.actions = await new Parse.Query('Actions')
      .descending('createdAt')
      .equalTo('job', this.currentJob).include(['type'])
      .limit(20000)
      .find();
    const _usersInLogs = _.uniqBy(this.actions.map(o => o.get('by')), (o) => o.id)
    const _usersProfiles = await new Parse.Query('Profile').containedIn('owner', _usersInLogs).find();
    _usersProfiles.forEach(p => {
      this.userProfiles[p.attributes.owner.id] = p;
    })
    console.log(this.userProfiles);
  }

  closeModal() {
    this.modalController.dismiss();
  }

  logRow(row: any) {
    console.log(row);
  }
}
