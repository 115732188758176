// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.changePasswordModalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.changePasswordModalFormContainer {
  width: 80%;
  margin-top: 25px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/change-password-modal/change-password-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;;AAEA;EACE,UAAA;EACA,gBAAA;AACF","sourcesContent":[".changePasswordModalContainer {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n}\r\n\r\n.changePasswordModalFormContainer {\r\n  width: 80%;\r\n  margin-top: 25px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
