import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {ModalService} from "../providers/modal.service";
import {CurrentUserService} from "../providers/current-user.service";


@Component({
  selector: 'app-login',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {

  constructor(private router: Router, private modalService: ModalService, private cus: CurrentUserService) {
  }

  async ngOnInit() {
    await this.cus.getCurrentUser();
    if (this.cus.currentUser.value?.get('passwordChangeNeeded')) {
      this.modalService.openChangePasswordModal();
    }
  }


}
