import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ActionSheetController, ModalController} from "@ionic/angular";
import * as Parse from 'parse';
import {ParseUtilities} from "../../../Utilities/parse.utilities";
import {CurrentUserService} from "../../../providers/current-user.service";
import {ToasterService} from "../../../providers/toaster.service";
import {LoadingService} from "../../../providers/loading.service";
import {Uploads} from "../../../Entities/Uploads";
import {TranslatorService} from "../../../providers/translator.service";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../../environments/environment";


@Component({
  selector: 'app-files-manager-modal',
  templateUrl: './files-manager-modal.component.html',
  styleUrls: ['./files-manager-modal.component.scss'],
})
export class FilesManagerModalComponent implements OnInit {

  userUploads: Parse.Object[];
  imagesLoaded = false;
  fileDownloadUrl: any;
  fileDownloadName: any;
  isProd: boolean;

  @ViewChild('fileDownloadButton') fileDownloadButton;
  @Input() mode: string;


  constructor(
    private actionSheetController: ActionSheetController,
    private modalController: ModalController,
    private user: CurrentUserService,
    private toaster: ToasterService,
    private loading: LoadingService,
    private translate: TranslateService,
  ) {
    this.isProd = environment.production;
  }

  async ngOnInit() {
    try {
      this.userUploads = await new Uploads().query.findAll();
      this.imagesLoaded = true;
    } catch (e) {
      await this.toaster.showUnexpectedErrorToast(e.message);
    }
  }

  async presentActionSheet(obj: Parse.Object) {
    const actionSheetOptions = {
      header: this.translate.instant('file'),
      subHeader: this.translate.instant('makeChoice'),
      buttons: [
        {
          text: this.translate.instant('download'),
          icon: 'download',
          handler: () => {
            this.fileDownloadUrl = obj.get('file')._url;
            this.fileDownloadName = obj.get('file')._name;
            this.fileDownloadButton.nativeElement.click();
          }
        },
        {
          text: this.translate.instant('delete'),
          role: 'destructive',
          icon: 'trash',
          handler: () => {
            this.deleteUpload(obj);
          }
        },
        {
          text: this.translate.instant('cancel'),
          icon: 'close',
          role: 'cancel',
          handler: () => {
          }
        }
      ]
    };
    if (this.mode === 'select') {
      actionSheetOptions.buttons.unshift({
        text: this.translate.instant('select'),
        icon: 'arrow-redo-outline',
        handler: () => {
          this.modalController.dismiss(obj);
        }
      })
    }
    const actionSheet = await this.actionSheetController.create(actionSheetOptions);
    await actionSheet.present();

  }


  closeModal() {
    this.modalController.dismiss();
  }

  async onFileSelect(e) {
    console.log(e);
  }

  async onFileChosen(e) {
    const selectedFile = e.target.files[0];
    const selectedFileName = selectedFile.name;
    const fileBase64 = await this.fileToBase64(e.target.files[0]);
    const parseFile = new Parse.File(selectedFileName, {base64: fileBase64},);
    try {
      await this.loading.presentLoading();
      const newUpload = await new Uploads().save({fileType: selectedFile.type, file: parseFile});
      this.userUploads.push(newUpload);
      await this.loading.removeLoading();
    } catch (error) {
      await this.toaster.showUnexpectedErrorToast(error.message);
      await this.loading.removeLoading();
    }
  }

  async fileToBase64(file: File): Promise<any> {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => res(reader.result);
      reader.onerror = error => rej(error);
    })

  }

  async deleteUpload(obj: Parse.Object) {
    try {
      await this.loading.presentLoading();
      await obj.destroy();
      this.userUploads = this.userUploads.filter(v => v.id !== obj.id);
      await this.loading.removeLoading();
    } catch (e) {
      await this.toaster.showErrorToast('cantDelete');
      await this.loading.removeLoading();
    }
  }
}
