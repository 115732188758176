import {ParseObject} from "./Parse.object";

export class EquipmentsType extends ParseObject {

  constructor() {
    super('EquipmentsType');
  }


}
