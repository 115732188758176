import {ParseObject} from "./Parse.object";

export class Suggestions extends ParseObject {

  constructor() {
    super('Suggestions');
  }


}
