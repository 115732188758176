import { Injectable } from '@angular/core';
import {LoadingController} from '@ionic/angular';
import {marker as _} from "@biesbjerg/ngx-translate-extract-marker";
import {TranslateService} from "@ngx-translate/core";


const loadingMessage = _('loadingMessage');

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loading: HTMLIonLoadingElement;

  constructor(private loadingController: LoadingController, private translate: TranslateService) {

  }


   async presentLoading() {
     this.loading = await this.loadingController.create({
       message: this.translate.instant('loadingMessage')
     });
     this.loading.present();
  }

  async removeLoading(){
    setTimeout(async () => {
      await this.loadingController.dismiss();
    }, 1000)
  }
}
