import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import {AuthComponent} from "./auth.component";
import {SharedModule} from "../shared/shared.module";
import {SignupComponent} from "./signup/signup.component";
import {LoginComponent} from "./login/login.component";
import {VerifyEmailComponent} from "./verify-email/verify-email.component";
import {LottieModule} from "ngx-lottie";
import {MakeProfileComponent} from "./make-profile/make-profile.component";
import {ForgotPasswordComponent} from "./forgot-password/forgot-password.component";


@NgModule({
  declarations: [AuthComponent, SignupComponent, LoginComponent, VerifyEmailComponent, MakeProfileComponent, ForgotPasswordComponent],
  imports: [
    SharedModule,
    CommonModule,
    AuthRoutingModule,
    LottieModule,
  ],
  exports: []
})
export class AuthModule { }
