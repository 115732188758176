import {ParseObject} from "./Parse.object";

export class Equipments extends ParseObject {

  constructor() {
    super('Equipments');
  }


}
