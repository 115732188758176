import { Injectable } from '@angular/core';
import {ModalController} from "@ionic/angular";
import {ProfileModalComponent} from "../shared/components/profile-modal/profile-modal.component";
import {FilesManagerModalComponent} from "../shared/components/files-manager-modal/files-manager-modal.component";
import {AddEquipmentsModalComponent} from "../shared/components/add-equipments-modal/add-equipments-modal.component";
import {SuggestionModalComponent} from "../shared/components/suggestion-modal/suggestion-modal.component";
import {UserManagementComponent} from "../main/user-management/user-management.component";
import {
  NotificationsSettingsModalComponent
} from "../shared/components/notifications-settings-modal/notifications-settings-modal.component";
import {
  UserManagementEditModalComponent
} from "../shared/components/user-management-edit-modal/user-management-edit-modal.component";
import {JobLogModalComponent} from "../shared/components/job-log-modal/job-log-modal.component";
import {ChangePasswordModalComponent} from "../shared/components/change-password-modal/change-password-modal.component";
import {CurrentUserService} from "./current-user.service";

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private modalController: ModalController, private _modal: ModalController, private cus: CurrentUserService) { }


  async openProfileModal(props?) {
    const modal = await this.modalController.create({
      component: ProfileModalComponent,
      componentProps: props
    });
    await modal.present();
  }

  async openChangePasswordModal(props?) {
    const modal = await this.modalController.create({
      canDismiss: !this.cus.currentUser.value?.get('passwordChangeNeeded'),
      component: ChangePasswordModalComponent,
      componentProps: props
    });
    await modal.present();
  }

  async openJobLogModal(props?) {
    const modal = await this.modalController.create({
      component: JobLogModalComponent,
      componentProps: props
    });
    await modal.present();
  }

  async openFilesManagerModal() {
    const modal = await this.modalController.create({
      component: FilesManagerModalComponent, id: '123'
    });
    await modal.present();
  }

  async openAddEquipmentsModal() {
    const modal = await this.modalController.create({
      component: AddEquipmentsModalComponent
    });
    await modal.present();
  }

  async openUserManagementModal() {
    const modal = await this.modalController.create({
      component: UserManagementComponent,
      cssClass: 'jobDetailsModal'
    });
    await modal.present();
  }

  async openSuggestionModal() {
    const modal = await this.modalController.create({
      component: SuggestionModalComponent
    });
    await modal.present();
  }

  async openNotificationsSettingsModal() {
    const modal = await this.modalController.create({
      component: NotificationsSettingsModalComponent
    });
    await modal.present();
  }

}
