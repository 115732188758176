import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import * as Parse from "parse";
import {ToasterService} from "../../providers/toaster.service";
import {LoadingService} from "../../providers/loading.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SwiperOptions} from "swiper";
import {SwiperComponent} from "swiper/angular";
// import {IonSl} from "@ionic/angular";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: UntypedFormGroup;
  changePasswordForm: UntypedFormGroup;
  codeSent: boolean = false;

  @ViewChild('swiper', {static: false}) swiper?;


  slideOpts: SwiperOptions = {
    slidesPerView: 1, allowTouchMove: false, autoHeight: true, keyboard: false
  }

  constructor(private router: Router, public route: ActivatedRoute, private fb: UntypedFormBuilder, private toaster: ToasterService, private loading: LoadingService) {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });

    this.changePasswordForm = this.fb.group({
      password: ['', Validators.required],
      resetCode: ['', Validators.required],
      passwordCheck: ['', Validators.required],
    }, {validators: this.matchingPasswords('password', 'passwordCheck')});
  }

  ngOnInit() {
    this.route.queryParams.subscribe(p => {
      this.codeSent = p.codeSent;
    });
  }

  async resetPassword() {
    try {
      if (this.forgotPasswordForm.valid) {
        this.loading.presentLoading();
        await Parse.Cloud.run('forgotPassword', this.forgotPasswordForm.value);
        this.loading.removeLoading();
        this.toaster.showSuccessToast('forgotPasswordEmailSent');
        this.router.navigateByUrl('/forgot-password?codeSent=true');
        this.forgotPasswordForm.reset();

      }
    } catch (e) {
      this.loading.removeLoading();
      this.toaster.showErrorToast(e.message);
    }

  }

  async changePassword() {
    console.log('Change password!!');
    try {
      if (this.changePasswordForm.valid) {
        this.loading.presentLoading();
        await Parse.Cloud.run('changePassword', {password: this.changePasswordForm.get('password').value, passwordCheck: this.changePasswordForm.get('passwordCheck').value, code:this.changePasswordForm.get('resetCode').value.toString()});
        this.loading.removeLoading();
        this.toaster.showSuccessToast('changePasswordSuccess');
        this.router.navigateByUrl('/login');
      }
    } catch (e) {
      this.loading.removeLoading();
      this.toaster.showErrorToast(e.message);
      switch (true) {
        case e.message === 'invalidCode':
          this.router.navigateByUrl('/forgot-password');
          break;

        case e.message === 'codeExpired':
          this.router.navigateByUrl('/forgot-password');
          break;
      }

    }
  }


  matchingPasswords(passwordKey: string, confirmPasswordKey: string) {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      const password = group.controls[passwordKey];
      const confirmPassword = group.controls[confirmPasswordKey];

      if (password.value !== confirmPassword.value) {
        return {
          mismatchedPasswords: true
        };
      }
    };
  }

  async verifyCode() {
    try {
      await Parse.Cloud.run('verifyChangePasswordCode', {code: this.changePasswordForm.get('resetCode').value.toString()})
      this.swiper.slideNext();
    } catch(e) {
      this.toaster.showErrorToast(e.message);
      this.changePasswordForm.reset();
      if (e.message == 'codeExpired') {
        this.router.navigateByUrl('/forgot-password');
      }
    }
  }
}
