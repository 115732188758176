import {NgModule} from '@angular/core';
import {MainRoutingModule} from './main-routing.module';
import {SharedModule} from "../shared/shared.module";
import {NgxMapLibreGLModule} from "@maplibre/ngx-maplibre-gl";

import {RouteDataService} from "../providers/route-data.service";

import {MainComponent} from "./main.component";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {EquipmentsComponent} from "./equipments/equipments.component";
import {RequestsComponent} from "./requests/requests.component";
import {NewRequestComponent} from "./new-request/new-request.component";
import {RequestDetailsComponent} from "./request-details/request-details.component";
import {MapComponent} from "./map/map.component";
import {UserManagementComponent} from "./user-management/user-management.component";
import {CdkScrollable} from "@angular/cdk/overlay";

@NgModule({
  declarations: [
    UserManagementComponent,
    MainComponent,
    DashboardComponent,
    EquipmentsComponent,
    RequestsComponent,
    NewRequestComponent,
    RequestDetailsComponent,
    MapComponent
  ],
    imports: [
        SharedModule,
        MainRoutingModule,
        NgxMapLibreGLModule,
        CdkScrollable
    ],
  providers: [RouteDataService]
})
export class MainModule {
}
