import {Injectable} from '@angular/core';
import {CurrentUserService} from "./current-user.service";
import {Equipments} from "../Entities/Equipments";
import {InsuranceProofs} from "../Entities/InsuranceProofs";
import {Router} from "@angular/router";
import {ModalService} from "./modal.service";
import {NotificationSubscriptions} from "../Entities/NotificationSubscriptions";
import {SwPush} from "@angular/service-worker";
import {Notifications} from "../Entities/Notifications";
import {ParseUtilities} from "../Utilities/parse.utilities";

interface StaticNotification {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  message_en: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  message_fr: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  action: { label_en: string; label_fr: string; func: () => void };
}

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  readonly VAPID_PUBLIC_KEY = 'BAr72zc3_74PRE-La7-0yCBVYpCVK0B-o07-dFIe1cwmXolGBRfHA4RxpUeERpGMEGm-B0I6bjXkfw58Cp6ZhjM';
  readonly VAPID_PRIV_KEY = '-PKe3kKRWZ_LtMs-zjbUNTI5zEbCDsXN25xpxwiBrnc';

  subQuery: Parse.LiveQuerySubscription;

  public allUserNotifications: Parse.Object[];
  public staticNotifications: StaticNotification[];

  constructor(private currentUser: CurrentUserService, private router: Router, private modal: ModalService,private push: SwPush, ) {

    // this.getUserNotifications();
  }

  private async getUserNotifications(limit:number = 10) {
    const query = await new Notifications().query;
    query.limit(limit);
    this.subQuery = await query.subscribe();
    this.allUserNotifications = await query.find();
    console.log(this.allUserNotifications);
    this.subQuery.on('create', o => console.log(o));
    this.subQuery.on('update', o => console.log(o));
    setInterval(() => {
      console.log(this.allUserNotifications);
    }, 2000)
  }

  public async getStaticNotifications() {
    const notifs: StaticNotification[] = [];
    const numEquipments = await new Equipments().query.count();
    const numInsuranceProofs = await new InsuranceProofs().query.count();
    const notificationSubscription = await new NotificationSubscriptions().query.first();
    if (!notificationSubscription) {
      // notifs.push({
      //   message_en: 'For a better experience, please enable notifications.',
      //   message_fr: "Pour une meilleure exprérience, veuillez activer les notifications.",
      //   action: {
      //     label_en: 'Enable notifications',
      //     label_fr: 'Activer les notifications',
      //     func: () => {
      //       this.requestSubscription();
      //     }
      //   }
      // })
    }
    // Notification si aucun equipement est ajouter
    if (numEquipments === 0) {
      notifs.push({
        message_en: 'You must add at least one equipment that you own to be able to bid on new jobs',
        message_fr: "Vous devez ajouter au minimum un équipement afin d'être en mesure de miser sur des nouvelles demandes de transports",
        action: {
          label_en: 'Add equipment',
          label_fr: 'Ajouter équipement',
          func: () => {
            this.router.navigateByUrl('equipments');
          }
        }
      })
    }
    // Notification si aucune preuve dassurance
    if (this.currentUser.isRole('Transporteur') && numInsuranceProofs === 0) {
      notifs.push({
        message_en: "You must add a proof of insurance otherwise you won't be able to bid on new jobs.",
        message_fr: "Vous devez ajouter une preuve d'assurance pour être en mesure de miser sur de nouvelles demandes de transports",
        action: {
          label_en: 'Add insurance proof',
          label_fr: 'Ajouter preuve assurance',
          func: () => {
            this.modal.openProfileModal({navigateToInsurance: true});
          }
        }
      })
    }
    this.staticNotifications = notifs;

  }

  async requestSubscription() {
    try {
      const sub = await this.push.requestSubscription({serverPublicKey: this.VAPID_PUBLIC_KEY})
      console.log(sub);
      const newSub = await new NotificationSubscriptions();
      newSub.set('endpoint', sub.endpoint);
      newSub.set('subObject', JSON.stringify(sub));
      await newSub.save();
    } catch (e) {
      console.log(e);
      console.log('Could not subscribe to notifications');
    }
  }

}
