import { Injectable } from '@angular/core';
import {ParseUtilities} from "../Utilities/parse.utilities";
import {BehaviorSubject} from "rxjs";
import * as Parse from 'parse';
import {Router} from "@angular/router";
import {TranslatorService} from "./translator.service";

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {

  currentUserRoles: BehaviorSubject<Parse.Role[]>;
  currentUser: BehaviorSubject<Parse.User>;

  constructor(private router: Router) {
    this.currentUserRoles = new BehaviorSubject<Parse.Role[]>([]);
    this.currentUser = new BehaviorSubject<Parse.User>(undefined);
    this.getCurrentUser();
  }

  public async fetchUserRoles() {
    this.currentUserRoles.next(await ParseUtilities.getUserRoles(Parse.User.current()));
  }

  public async getCurrentUser() {
    try {
      await this.currentUser.next( Parse.User.current());
      await this.fetchUserRoles();
    } catch (e) {
      throw e;
    }
  }

  public isRole(roleName: string) {
    return this.currentUserRoles.getValue().some(r => r.getName() === roleName);
  }


}
