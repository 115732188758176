import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jobPriorityColor'
})
export class JobPriorityColorPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    switch (value) {
      case 1:
        return 'danger'
      case 2:
        return 'medium'
    }
  }

}
