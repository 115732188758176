import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'freightType'
})
export class FreightTypePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    switch (value) {
      case 'vehicle' :
        return '<img src="assets/icon/vehicle.png">'

      case 'motorcycle' :
        return '<img src="assets/icon/motorcycle.png">'

      case 'heavyEquipment' :
        return '<img src="assets/icon/heavyEquipment.png">'

      case 'atv' :
        return '<img src="assets/icon/atv.png">'

      case 'freight' :
        return '<img src="assets/icon/freight.png">'
    }
  }

}
