import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as Parse from "parse";
import {CurrentUserService} from "./current-user.service";
import {marker} from '@biesbjerg/ngx-translate-extract-marker';

marker('saveSuccessMessage');
marker('saveErrorMessage');
marker('unexpectedErrorMessage');
marker('deleteSuccessMessage');
marker('wait5Minutes');
marker('sameEmail');
marker('emailChangeSuccess');
marker('emailAlreadyInUse');
marker('cantDelete');
marker('delete');
marker('file');
marker('delete');
marker('cancel');
marker('download');
marker('equipments');
marker('add');
marker('addEquipment');
marker('addImage');
marker('select');
marker('selectEquipmentType');
marker('emailNotVerified');
marker('suggestionSent');
marker('invalidUserOrPassword');
marker('userAlreadyTaken');
marker('emailSent');
marker('forgotPasswordEmailSent');
marker('changePasswordSuccess');
marker('invalidForm');
marker('invalidCode');
marker('codeExpired');
marker('newSwVersionAvailable');
marker('newUpdate');
marker('update');
marker('loadingAddress');
marker('unloadingAddress');
marker('loadingDate');
marker('unloadingDate');
marker('freightType');
marker('areYouSure');
marker('yes');
marker('no');
marker('permanentDeleteText');
marker('resendNotifications');
marker('resendNotificationsText');
marker('enterYourBid');
marker('placeBid');
marker('bidSuccessful');
marker('thisJobHasWinner');
marker(`cantDeleteHaveWinner`);
marker(`jobIsNotApproved`);
marker(`cantDeleteJobCompleted`);
marker(`cantBidJobCompleted`);
marker(`emailChangedPleaseLoginUsingNewEmail`);
marker(`emailChangedSuccessHeader`);
marker(`emailCHangedSuccessMessage`);
marker(`bidCanceledSuccessfuly`);
marker(`bidCantBe0`);
marker(`searchString`);
marker(`completeJobText`);
marker(`pswLength3`);
marker(`logAction_bidModified`);
marker(`logAction_bidDelete`);
marker(`logAction_unassign`);
marker(`logAction_assign`);
marker(`logAction_adminNote`);
marker(`logAction_bid`);
marker(`logAction_delete`);
marker(`logAction_complete`);
marker(`logAction_modify`);
marker(`logAction_approve`);
marker(`amount`);
marker(`clientAreaLabel`);
marker(`status.all`);
marker(`status.waitingApproval`);
marker(`status.waitingForBids`);
marker(`status.waitingForCompletion`);
marker(`status.waitingForClientAnswer`);
marker(`status.waitingForCarrierAnswer`);
marker(`status.cancelRequestAsked`);
marker(`enterCancelReason`);
marker(`cancelRequestAlertHeader`);
marker(`cancelRequestAlertSub`);
marker(`cancelRequestAlertButtonText`);
marker(`tour.client.requests.segmentAllContent`);
marker(`tour.client.requests.segmentAllTitle`);
marker(`tour.client.requests.segmentToBeApprovedContent`);
marker(`tour.client.requests.segmentToBeApprovedTitle`);
marker(`tour.client.requests.segmentAssignedContent`);
marker(`tour.client.requests.segmentAssignedTitle`);
marker(`tour.client.requests.segmentCompletedContent`);
marker(`tour.client.requests.segmentCompletedTitle`);
marker(`tour.client.requests.segmentDeletedContent`);
marker(`tour.client.requests.segmentDeletedTitle`);
marker(`tour.menu.requestsButtonContent`);
marker(`tour.menu.requestsButtonTitle`);
marker(`tour.menu.profileButtonContent`);
marker(`tour.menu.profileButtonTitle`);
marker(`tour.menu.notificationsButtonContent`);
marker(`tour.menu.notificationsButtonTitle`);
marker(`tour.menu.suggestionsButtonContent`);
marker(`tour.menu.suggestionsButtonTitle`);





@Injectable({
  providedIn: 'root'
})
export class TranslatorService {

  constructor(private translate: TranslateService, private current: CurrentUserService) {
    this.translate.addLangs(['en']);
    const browserLang = this.translate.getBrowserLang() === 'fr' ? 'fr' : 'en';
    const savedLang = localStorage.getItem('lang');
    if (savedLang === 'en' || savedLang === 'fr') {
      this.changeLang(savedLang);
    } else {
      this.changeLang(browserLang);
    }
  }

  public get currentLang(): string {
    return this.translate.currentLang;
  }

  public async changeLang(lang: string) {
    this.translate.use(lang);
    localStorage.setItem('lang', lang);
    const user = this.current.currentUser.value;
    if (user) {
      user.set('lang', lang);
      try {
        await user.save();
      } catch (e) {
        console.log(e);
      }
    }
  }

  public getLangs(): string[] {
    return this.translate.getLangs();
  }

}
