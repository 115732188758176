import {ParseObject} from "./Parse.object";

export class Uploads extends ParseObject {

  constructor() {
    super('Uploads');
  }


}
