import {ParseObject} from "./Parse.object";

export class InsuranceProofs extends ParseObject {

  constructor() {
    super('InsuranceProofs');
  }


}
