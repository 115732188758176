import * as Parse from 'parse';


export class ParseObject extends Parse.Object {

  query: Parse.Query;

  constructor(className: string) {
    super(className);
    this.query = new Parse.Query(this.className);
  }

}
