import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import * as Parse from 'parse';
import {TranslateService} from "@ngx-translate/core";
import * as _ from 'lodash';
import {ColumnMode} from "@swimlane/ngx-datatable";
import {AlertController, ModalController} from "@ionic/angular";
import {ToasterService} from "../../providers/toaster.service";
import {
  UserManagementEditModalComponent
} from "../../shared/components/user-management-edit-modal/user-management-edit-modal.component";

export interface UserByRole {
  role: Parse.Role;
  usersWithProfile: UserWithProfile[];
}

export interface UserWithProfile {
  user: Parse.User,
  profile: Parse.Object
}

export interface UsersByRole extends Array<UserByRole> {
}

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})

export class UserManagementComponent implements OnInit {
  ColumnMode = ColumnMode;
  public usersByRoles: UsersByRole = [];
  public searchValue: string;
  public filteredUsersByRoles: UsersByRole = [];

  constructor(private _modalCtrl: ModalController, private modalCtrl: ModalController, private changeRef: ChangeDetectorRef, public trans: TranslateService, private alertCtrl: AlertController, private toaster: ToasterService) {
  }

  async ngOnInit() {
    // const profiles = await new Parse.Query('Profile').findAll({batchSize: 1000});
    const roles = await new Parse.Query(Parse.Role).findAll({batchSize: 1000});
    this.usersByRoles = await Promise.all(roles.map(async (role) => {
      const userInRoleInnerQuery = role.getUsers().query().equalTo('profileCompleted', true);
      const profiles = await new Parse.Query('Profile')
        .matchesQuery('owner', userInRoleInnerQuery)
        .include('owner')
        .findAll({batchSize: 1000})
      return {
        role: role, usersWithProfile: profiles.map(p => {
          return {user: p.get('owner'), profile: p}
        })
      }
    }))
    console.log(this.usersByRoles);
  }

  handleChange($event: any) {
    this.searchValue = $event.detail.value;
    this.filteredUsersByRoles = _.map(this.usersByRoles, obj => {
      return {
        role: obj.role,
        usersWithProfile:
          obj.usersWithProfile.filter(o => {
            const usersAttributesContainsValue = Object.values(o.user.attributes).some(v => JSON.stringify(v).toLowerCase().includes(this.searchValue.toLowerCase()))
            const usersProfileAttributesContainsValue = Object.values(o.profile.attributes).some(v => JSON.stringify(v).toLowerCase().includes(this.searchValue.toLowerCase()))
            if (usersAttributesContainsValue || usersProfileAttributesContainsValue) {
              return o;
            }
          })
      }
    })
  }

  logValue(value: any) {
    console.log(value);
  }

  async banUser(row: UserWithProfile) {
    const alert = await this.alertCtrl.create({
      header: 'Bannir utilisateur',
      message: this.trans.instant('areYouSure'),
      buttons: [
        {
          text: this.trans.instant('cancel'),
          role: 'cancel'
        },
        {
          text: this.trans.instant('yes'),
          cssClass: 'color-danger',
          handler: async () => {
            try {
              await row.user.save({isBanned: true});
              this.changeRef.detectChanges();
            } catch (e) {
              await this.toaster.showUnexpectedErrorToast(e.message);
            }
          }
        }
      ]
    })
    alert.present();
  }

  async modifyUser(row: UserWithProfile) {
    const _modal = await this._modalCtrl.create({
      component: UserManagementEditModalComponent,
      cssClass: 'jobDetailsModal',
      componentProps: {userWithProfile: row}
    });
    await _modal.present();
  }

  async unbanUser(row: UserWithProfile) {
    try {
      await row.user.save({isBanned: false});
    } catch(e) {
      this.toaster.showUnexpectedErrorToast(e);
    }
  }

  async certifyChanged($event: any, row: UserWithProfile) {
    const checked = $event.detail.checked;
    try {
      await row.user.save({isCertified: checked})
    } catch(e) {
      this.toaster.showUnexpectedErrorToast(e);
    }

  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
