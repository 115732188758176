import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {LoadingService} from "../../providers/loading.service";
import * as Parse from "parse";
import {UserService} from "../../providers/user.service";
import {CurrentUserService} from "../../providers/current-user.service";
import {ToasterService} from "../../providers/toaster.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  loginForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private router: Router, private loading: LoadingService, private currentUser: CurrentUserService, private toaster: ToasterService) {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  async ngOnInit() {
  }

  async logIn() {
    if (this.loginForm.valid) {
      const {username, password} = this.loginForm.value;
      try {
        const u = await Parse.User.logIn(username, password);
        this.currentUser.currentUser.next(u);
        this.router.navigate(['dashboard']);
      } catch (e) {
        if (e.code === 101) {
          this.toaster.showErrorToast('invalidUserOrPassword');
        } else {
          this.toaster.showUnexpectedErrorToast(e.message);
        }
        this.loginForm.reset();
      }
    }
  }

//  Fonction to pass a next input focus
  gotoNextField(nextElement) {
    nextElement.setFocus();
  }

}
