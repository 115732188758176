import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import * as Parse from 'parse';
import {LoadingService} from '../../providers/loading.service';
import {Router} from '@angular/router';
import {TranslatorService} from "../../providers/translator.service";
import {ToasterService} from "../../providers/toaster.service";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  signupForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private loading: LoadingService, private router: Router, private translate: TranslatorService, private toaster: ToasterService) {
    this.signupForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      passwordCheck: ['', Validators.required],
    }, {validators: this.matchingPasswords('password', 'passwordCheck')});
  }

  ngOnInit() {}

  async signUp() {
    if (this.signupForm.valid) {
      const user = new Parse.User();
      user.set('username', this.signupForm.value.email);
      user.set('email', this.signupForm.value.email);
      user.set('password', this.signupForm.value.password);
      user.set('lang', this.translate.currentLang);
      try {
        await user.signUp();
        this.router.navigateByUrl('verify');
      } catch (e) {
        if (e.code === 202) {
          this.toaster.showErrorToast('userAlreadyTaken');
        } else {
          this.toaster.showUnexpectedErrorToast(e.message);
        }
        this.signupForm.reset();
      }
    }
  }


  matchingPasswords(passwordKey: string, confirmPasswordKey: string) {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      const password = group.controls[passwordKey];
      const confirmPassword = group.controls[confirmPasswordKey];

      if (password.value !== confirmPassword.value) {
        return {
          mismatchedPasswords: true
        };
      }
    };
  }
//  Fonction to pass a next input focus
  gotoNextField(nextElement){
    nextElement.setFocus();
  }
}
