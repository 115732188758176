// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.verify-text {
  width: 50%;
}

.verify-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.verify-container {
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("/assets/TRI-BG.png");
  background-size: cover;
}
.verify-container ion-card {
  width: 50%;
}
.verify-container ion-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 992px) {
  .verify-text {
    width: 80%;
  }
}
@media (max-width: 768px) {
  .verify-container ion-card {
    width: 90%;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/auth/verify-email/verify-email.component.scss","webpack://./src/mixins/responsive.scss"],"names":[],"mappings":"AAEA;EACE,UAAA;AADF;;AAIA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;AADF;;AAIA;EACE,WAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,2CAAA;EACA,sBAAA;AADF;AAGE;EACE,UAAA;AADJ;AAIE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AAFJ;;ACjBI;EDyBF;IACE,UAAA;EAJF;AACF;ACvBI;EDiCA;IACE,UAAA;EAPJ;AACF","sourcesContent":["@import 'responsive';\r\n\r\n.verify-text {\r\n  width: 50%\r\n}\r\n\r\n.verify-header {\r\n  display: flex;\r\n  flex-direction: row;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  flex-wrap:wrap;\r\n}\r\n\r\n.verify-container {\r\n  width: 100%;\r\n  min-height: 100%;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  background-image: url(\"/assets/TRI-BG.png\");\r\n  background-size: cover;\r\n\r\n  ion-card {\r\n    width: 50%;\r\n  }\r\n\r\n  ion-card-content {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n  }\r\n\r\n}\r\n\r\n@include screen(lg) {\r\n  .verify-text {\r\n    width: 80%\r\n  }\r\n}\r\n\r\n@include screen(md) {\r\n\r\n  .verify-container {\r\n    ion-card {\r\n      width: 90%;\r\n    }\r\n  }\r\n}\r\n","$breakpoints: (\r\n  'sm':  576px,\r\n  'md': 768px,\r\n  'lg':  992px,\r\n  'xl':  1200px\r\n) !default;\r\n\r\n@mixin screen($breakpoint) {\r\n  // If the key exists in the map\r\n  @if map-has-key($breakpoints, $breakpoint) {\r\n    // Prints a media query based on the value\r\n    @media (max-width: map-get($breakpoints, $breakpoint)) {\r\n      @content;\r\n    }\r\n  }\r\n\r\n    // If the key doesn't exist in the map\r\n  @else {\r\n    @warn \"Unfortunately, no value could be retrieved from `#{$breakpoint}`. \"\r\n        + \"Available breakpoints are: #{map-keys($breakpoints)}.\";\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
