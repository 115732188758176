import { Component, OnInit } from '@angular/core';
import {AlertController, ModalController, NavParams} from "@ionic/angular";
import {UserWithProfile} from "../../../main/user-management/user-management.component";
import {FormBuilder, FormGroup} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {ToasterService} from "../../../providers/toaster.service";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";

@Component({
  selector: 'app-user-management-edit-modal',
  templateUrl: './user-management-edit-modal.component.html',
  styleUrls: ['./user-management-edit-modal.component.scss'],
})
export class UserManagementEditModalComponent implements OnInit {

  public readonly emailRegex = new RegExp("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$");

  public userWithProfile: UserWithProfile;
  public userForm: FormGroup;
  public profileForm: FormGroup;
  constructor(private toaster: ToasterService, private trans: TranslateService, private alertCtrl: AlertController, private modalController: ModalController, private nav: NavParams, private fb: FormBuilder) {
    this.userWithProfile = this.nav.get('userWithProfile');
    this.userForm = this.fb.group(
      {
        username: [''],
        email: [''],
      }
    )
    this.userForm.patchValue(this.userWithProfile.user.attributes);
    this.userForm.valueChanges.pipe(debounceTime(600), distinctUntilChanged()).subscribe(val => {
      if (this.userForm.valid) {
        this.saveUserInfo();
      }
    })

    this.profileForm = this.fb.group(
      {
        first_name: [],
        last_name: [],
        phone_number: [],
        address: [],
        tps: [],
        tvq: [],
        companyName: [],
      }
    )
    this.profileForm.patchValue(this.userWithProfile.profile.attributes);
    this.profileForm.valueChanges
      .pipe(
        debounceTime(600),
        distinctUntilChanged())
      .subscribe(val => {
      if (this.profileForm.valid) {
        this.saveProfileInfo();
      }
    })

  }

  ngOnInit() {

  }

  closeModal() {
    this.modalController.dismiss();
  }

  async changePsw() {
    const alert = await this.alertCtrl.create({
      header: this.trans.instant('enterNewPasswordText'),
      subHeader: "Cette action changera le mot de passe de l'utilistateur",
      cssClass: 'delete-alert',
      buttons: [
        {
          text: this.trans.instant('cancel'),
          role: 'cancel',
        },
        {
          text: this.trans.instant('confirm'),
          role: 'confirm',
          cssClass: 'color-success',
          handler: async ({psw}) => {
            try {
              if (psw.length <= 3) {
                this.toaster.showErrorToast('pswLength3');
                return;
              }
              this.userWithProfile.user.save({password: psw});
              this.toaster.showSaveSuccessToast();
            } catch (e) {
              this.toaster.showUnexpectedErrorToast(e.code);
            }
          }
        },
      ],
      inputs: [
        {
          name: 'psw',
          type: 'search',
          placeholder: 'Inscrivez le nouveau mot de passe',
          attributes: {autocomplete: 'nope', autofocus: true}
        },
      ]
    })
    await alert.present();
  }

  async saveUserInfo() {
    try {
      await this.userWithProfile.user.save(this.userForm.value);
      this.toaster.showSaveSuccessToast();
    } catch (e) {
      this.toaster.showErrorToast(e.code);
    }
  }

  async saveProfileInfo() {
    try {
      await this.userWithProfile.profile.save(this.profileForm.value);
      this.toaster.showSaveSuccessToast();
    } catch (e) {
      this.toaster.showErrorToast(e.code);
    }
  }
}
