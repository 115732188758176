// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.equipment-image {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 100%;
  height: 150px !important;
}

.actions-column {
  display: flex !important;
  flex-direction: column !important;
}

.equipment-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.equipment-card {
  width: 250px;
}
.equipment-card .equipment-image {
  height: 180px !important;
  object-fit: cover !important;
}`, "",{"version":3,"sources":["webpack://./src/app/main/equipments/equipments.component.scss"],"names":[],"mappings":"AAAA;EACE,iCAAA;EACA,uCAAA;EACA,WAAA;EACA,wBAAA;AACF;;AAEA;EACE,wBAAA;EACA,iCAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AACF;;AAEA;EACE,YAAA;AACF;AACE;EACE,wBAAA;EACA,4BAAA;AACJ","sourcesContent":[".equipment-image {\r\n  background-size: cover !important;\r\n  background-repeat: no-repeat !important;\r\n  width: 100%;\r\n  height: 150px !important;\r\n}\r\n\r\n.actions-column {\r\n  display: flex !important;\r\n  flex-direction: column !important;\r\n}\r\n\r\n.equipment-container {\r\n  display: flex;\r\n  flex-direction: row;\r\n  flex-wrap: wrap;\r\n}\r\n\r\n.equipment-card {\r\n  width: 250px;\r\n\r\n  .equipment-image {\r\n    height: 180px !important;\r\n    object-fit: cover !important;\r\n  }\r\n\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
