import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthComponent} from "./auth.component";
import {LoginComponent} from "./login/login.component";
import {SignupComponent} from "./signup/signup.component";
import {LoginGuard} from "../providers/login.guard";
import {VerifyEmailComponent} from "./verify-email/verify-email.component";
import {TranslateModule} from "@ngx-translate/core";
import {MakeProfileComponent} from "./make-profile/make-profile.component";
import {ForgotPasswordComponent} from "./forgot-password/forgot-password.component";

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    canActivateChild: [LoginGuard],
    children: [
      {path: '', redirectTo: 'login', pathMatch: 'full'},
      {path: 'login', component: LoginComponent},
      {path: 'signup', component: SignupComponent},
      {path: 'forgot-password', component: ForgotPasswordComponent},

    ]
  },
  {path: 'verify', component: VerifyEmailComponent},
  {path: 'make-profile', component: MakeProfileComponent},
];

@NgModule({
  imports: [TranslateModule.forChild(), RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
